import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  SupportTeam,
  SupportTeamCallees,
  SupportTeamCreateParams,
  SupportTeamQueryParams,
  SupportTeamUpdateParams,
  SupportTeamsUpdateParams,
} from '../models/support-team.model';
import { ListResponse } from '../models/utility.model';
import { SupportTeamGateway } from '../usecases/support-team.gateway';

@Injectable()
export class SupportTeamService extends SupportTeamGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listSupportTeams(params: SupportTeamQueryParams): Observable<ListResponse<SupportTeam>> {
    return this._http.get<ListResponse<SupportTeam>>('api/com/support-teams', { params });
  }

  getSupportTeamCallees(teamId: string): Observable<SupportTeamCallees> {
    return this._http.get<SupportTeamCallees>(`api/com/support-teams/${teamId}/callees`);
  }

  createSupportTeam(params: SupportTeamCreateParams): Observable<SupportTeam> {
    return this._http.post<SupportTeam>('api/com/support-teams', params);
  }

  updateSupportTeam(teamId: string, params: SupportTeamUpdateParams): Observable<SupportTeam> {
    return this._http.put<SupportTeam>(`api/com/support-teams/${teamId}`, params);
  }

  updateSupportTeams(params: SupportTeamsUpdateParams): Observable<ListResponse<SupportTeam>> {
    return this._http.put<ListResponse<SupportTeam>>(`api/com/support-teams/supporters`, params);
  }

  deleteSupportTeam(teamId: string): Observable<void> {
    return this._http.delete<void>(`api/com/support-teams/${teamId}`);
  }
}
