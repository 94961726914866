import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HIDE_PROGRESS, ListResponse } from '@daikin-tic/dxone-com-lib';

import { Message, MessageCreateParams, MessageQueryParams, MessageUpdateParams } from '../models/message.model';
import { MessageGateway } from '../usecases/message.gateway';

@Injectable()
export class MessageService extends MessageGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listMessages(channelId: string, params: MessageQueryParams): Observable<ListResponse<Message>> {
    return this._http.get<ListResponse<Message>>(`api/rss/channels/${channelId}/messages`, { params });
  }

  createMessage(channelId: string, params: MessageCreateParams): Observable<Message> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.post<Message>(`api/rss/channels/${channelId}/messages`, params, options);
  }

  updateMessage(channelId: string, messageId: string, params: MessageUpdateParams): Observable<Message> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<Message>(`api/rss/channels/${channelId}/messages/${messageId}`, params, options);
  }
}
