import { ImmutableMap, Prop, QueryParams, RequiredOnly, isObject, isString } from './utility.model';

type GroupRequiredProps = {
  name: string;
  parent: string;
};

type GroupOptionalProps = object;

type GroupProvidedProps = {
  groupId: string;
  share: boolean;
  supportTeam: string;
  updatedAt: number;
  version: number;
};

export type GroupQueryParams = QueryParams;

export type GroupCreateParams = GroupRequiredProps & GroupOptionalProps;

export type GroupUpdateParams = Pick<GroupRequiredProps, 'name'> & GroupOptionalProps;

export type Group = GroupRequiredProps & GroupOptionalProps & GroupProvidedProps;

export function isGroupCreateParams(value: unknown): value is GroupCreateParams {
  const sProps: Prop<RequiredOnly<GroupCreateParams>, string>[] = ['name', 'parent'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}

export function isGroupUpdateParams(value: unknown): value is GroupUpdateParams {
  const sProps: Prop<RequiredOnly<GroupCreateParams>, string>[] = ['name', 'parent'];
  return isObject(value) && sProps.some(prop => isString(value[prop]));
}

export class Groups extends ImmutableMap<string, Group> {
  constructor(groups?: Group[] | Iterable<Group>) {
    super((group: Group) => group.groupId, groups);
  }

  protected clone(groups?: Group[] | Iterable<Group>): this {
    return new Groups(groups) as this;
  }
}
