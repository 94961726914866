import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { AccessPoint, AccessPointCreateParams, AccessPointQueryParams } from '../models/access-point.model';

export abstract class AccessPointGateway {
  abstract listAccessPoints(params: AccessPointQueryParams): Observable<ListResponse<AccessPoint>>;
  abstract createAccessPoint(params: AccessPointCreateParams): Observable<AccessPoint>;
  abstract deleteAccessPoint(accessPointId: string): Observable<void>;
}
