import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, partition } from 'rxjs';
import { delayWhen, filter, finalize, map, repeatWhen, takeUntil } from 'rxjs/operators';

import {
  AuthUsecase,
  DistinctSubject,
  NeverError,
  Period,
  PeriodSubject,
  UserTimezone,
  WebSocketSyncData,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import { Report, ReportQueryParams, Reports } from '../models/report.model';
import { Work } from '../models/work.model';
import { ReportGateway } from '../usecases/report.gateway';
import { ReportUsecase } from '../usecases/report.usecase';

@Injectable()
export class ReportInteractor extends ReportUsecase {
  get reports$(): Observable<Reports> {
    return this._reports;
  }
  get period$(): Observable<Period> {
    return this._period;
  }
  get workUpdateNotice$(): Observable<void> {
    return this._workUpdateNotice;
  }

  private readonly _reports = new DistinctSubject<Reports>(new Reports());
  private readonly _period = new PeriodSubject({ from: -1, to: -1 }, 14, 14);
  private readonly _workUpdateNotice = new BehaviorSubject<void>(void 0);

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _reportGateway: ReportGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._period.now();
    });

    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    this.period$
      .pipe(
        filter(({ from, to }) => from >= 0 && to >= 0),
        takeUntil(close$),
        finalize(() => this._reports.next(new Reports())),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => open$))),
      )
      .subscribe(({ from, to }) => this.listReports(from, to));

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'report'),
        map(({ data }) => data as WebSocketSyncData<Report>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._reports.next(this._reports.value.set(data.payload as Report));
            break;
          case 'delete': {
            this._reports.next(this._reports.value.delete((data.payload as Report).reportId));
            break;
          }
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'work'),
        map(({ data }) => data as WebSocketSyncData<Work>),
      )
      .subscribe(data => {
        const work = data.payload as Work;
        switch (data.reason) {
          case 'create':
            this.onCreateWork(work);
            break;
          case 'update':
            this.onUpdateWork(work);
            break;
          case 'delete':
            this.onDeleteWork(work.reportId, work.workId);
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  changeTimezone(timezone: UserTimezone): void {
    this._period.tz(timezone);
  }

  changePeriod(period: Period): void {
    this._period.next(period);
  }

  private onCreateWork(createdWork: Work): void {
    const report = this._reports.value.get(createdWork.reportId);
    if (report && !report.works?.find(({ workId }) => workId === createdWork.workId)) {
      report.works = [...(report.works || []), createdWork];
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private onUpdateWork(updatedWork: Work): void {
    const report = this._reports.value.get(updatedWork.reportId);
    if (report && report.works?.find(({ workId, version }) => workId === updatedWork.workId && version < updatedWork.version)) {
      report.works = report.works?.reduce((acc, cur) => {
        const work = cur.workId === updatedWork.workId ? updatedWork : cur;
        return [...acc, work];
      }, [] as Work[]);
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private onDeleteWork(reportId: string, workId: string): void {
    const report = this._reports.value.get(reportId);
    if (report) {
      report.works = report.works?.reduce((acc, cur) => {
        return cur.workId === workId ? acc : [...acc, cur];
      }, [] as Work[]);
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private listReports(from: number, to: number): void {
    const queryParams = {
      scheduledFrom: from.toString(),
      scheduledTo: to.toString(),
    } as ReportQueryParams;
    recursiveQuery(params => this._reportGateway.listReports(params), queryParams).subscribe(reports =>
      this._reports.next(new Reports(reports)),
    );
  }

  private onSignIn(): void {
    const { from, to } = this._period.value;
    this.listReports(from, to);
  }

  private onSignOut(): void {
    this._reports.next(new Reports());
  }
}
