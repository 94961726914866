import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Channel, ChannelCreateParams, ChannelQueryParams, ChannelUpdateParams } from '../models/channel.model';
import { ChannelGateway } from '../usecases/channel.gateway';

@Injectable()
export class ChannelService extends ChannelGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listChannels(params: ChannelQueryParams): Observable<ListResponse<Channel>> {
    return this._http.get<ListResponse<Channel>>('api/rss/channels', { params });
  }

  createChannel(params: ChannelCreateParams): Observable<Channel> {
    return this._http.post<Channel>('api/rss/channels', params);
  }

  updateChannel(channelId: string, params: ChannelUpdateParams): Observable<Channel> {
    return this._http.put<Channel>(`api/rss/channels/${channelId}`, params);
  }

  deleteChannel(channelId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/channels/${channelId}`);
  }
}
