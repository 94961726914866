import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { WifiNetwork, WifiNetworkCreateParams, WifiNetworkQueryParams } from '../models/wifi-network.model';
import { WifiNetworkGateway } from '../usecases/wifi-network.gateway';

@Injectable()
export class WifiNetworkService extends WifiNetworkGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listWifiNetworks(params: WifiNetworkQueryParams): Observable<ListResponse<WifiNetwork>> {
    return this._http.get<ListResponse<WifiNetwork>>('api/rss/wifi-networks', { params });
  }

  createWifiNetwork(params: WifiNetworkCreateParams): Observable<WifiNetwork> {
    return this._http.post<WifiNetwork>('api/rss/wifi-networks', params);
  }

  deleteWifiNetwork(wifiNetworkId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/wifi-networks/${wifiNetworkId}`);
  }
}
