import { ImmutableMap, Prop, QueryParams, RequiredOnly, isObject, isString } from '@daikin-tic/dxone-com-lib';

export const TAG_COLORS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info'] as const;
export type TagColor = (typeof TAG_COLORS)[number];

type TagRequiredProps = {
  name: string;
  color: TagColor;
};

type TagProvidedProps = {
  tagId: string;
  updatedAt: number;
  version: number;
};

export type TagQueryParams = QueryParams;

export type TagCreateParams = TagRequiredProps;

export type Tag = TagRequiredProps & TagProvidedProps;

export function isTagCreateParams(value: unknown): value is TagCreateParams {
  const sProps: Prop<RequiredOnly<TagCreateParams>, string>[] = ['name'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && TAG_COLORS.some(v => v === value['color']);
}

export class Tags extends ImmutableMap<string, Tag> {
  constructor(tags?: Tag[] | Iterable<Tag>) {
    super(tag => tag.tagId, tags);
  }

  protected clone(tags?: Tag[] | Iterable<Tag>): this {
    return new Tags(tags) as this;
  }
}
