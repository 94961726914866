import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Offer, OfferCreateParams, OfferMultipleCreateParams, OfferQueryParams, OfferUpdateParams } from '../models/offer.model';
import { OfferGateway } from '../usecases/offer.gateway';

@Injectable()
export class OfferService extends OfferGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listOffers(params: OfferQueryParams): Observable<ListResponse<Offer>> {
    return this._http.get<ListResponse<Offer>>('api/rss/offers', { params });
  }

  createOffer(params: OfferCreateParams): Observable<Offer> {
    return this._http.post<Offer>('api/rss/offers', params);
  }

  createOfferMultiple(params: OfferMultipleCreateParams): Observable<ListResponse<Offer>> {
    return this._http.post<ListResponse<Offer>>('api/rss/offers', params);
  }

  updateOffer(offerId: string, params: OfferUpdateParams): Observable<Offer> {
    return this._http.put<Offer>(`api/rss/offers/${offerId}`, params);
  }
}
