import { UAParser } from 'ua-parser-js';

import { ImmutableMap, Prop, QueryParams, isObject, isString } from './utility.model';

export const MAX_CALLEE_COUNT = 10;

export const DETECTABLE_DEVICE_TYPES = ['pc', 'handset'] as const;
export const ASSIGNABLE_DEVICE_TYPES = ['thinklet'] as const;
export const DEVICE_TYPES = [...DETECTABLE_DEVICE_TYPES, ...ASSIGNABLE_DEVICE_TYPES] as const;
export type DeviceType = (typeof DEVICE_TYPES)[number];

// TODO: UserAgent廃止.
const ua = UAParser(navigator.userAgent);
export const UA_DEVICE_TYPE: DeviceType = ua.device.type === 'mobile' ? 'handset' : 'pc';
export const isIOS: boolean = /iPhone|iPad|iPod/i.test(ua.device.model || '');

type DeviceRequiredProps = {
  name: string;
  owner: string;
  supportTeam: string;
  callees: string[];
  locationId: string;
};

type DeviceOptionalProps = object;

type DeviceProvidedProps = {
  deviceId: string;
  type: DeviceType;
  connectionId: string;
  organizationId: string;
  groupId: string;
  updatedAt: number;
  version: number;
};

export type DeviceQueryParams = QueryParams;

export type DeviceUpdateParams = Partial<DeviceRequiredProps> & DeviceOptionalProps;

export type Device = DeviceRequiredProps & DeviceOptionalProps & DeviceProvidedProps;

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isDeviceUpdateParams(value: unknown): value is DeviceUpdateParams {
  const sProps: Prop<DeviceUpdateParams, string>[] = ['name', 'owner', 'supportTeam', 'locationId'];
  return isObject(value) && (sProps.some(prop => isString(value[prop])) || isStringArray(value['callees']));
}

export class Devices extends ImmutableMap<string, Device> {
  constructor(devices?: Device[] | Iterable<Device>) {
    super((device: Device) => device.deviceId, devices);
  }

  protected clone(devices?: Device[] | Iterable<Device>): this {
    return new Devices(devices) as this;
  }
}
