import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

export const SKIP_TYPES = ['none', 'condition', 'manual', 'force', 'give-up'] as const;
export type SkipTypes = (typeof SKIP_TYPES)[number];

export const STEP_RESULT_TYPES = ['yesno', 'select', 'digit', 'naturalNumber', 'none'] as const;
export type StepResultTypes = (typeof STEP_RESULT_TYPES)[number];

export const UPLOAD_STATUS = ['in-progress', 'decoding', 'failed', 'succeed'] as const;
export type UploadStatus = (typeof UPLOAD_STATUS)[number];

export type ResultVideo = {
  name: string;
  upload: UploadStatus;
};

type ResultVoice = {
  name: string;
  upload: UploadStatus;
};

type StepOptionalProps = {
  result?: string;
  startedAt?: number;
  stoppedAt?: number;
  userId?: string;
  userName?: string;
  deviceName?: string;
  count?: number;
  skip?: SkipTypes;
};

type StepProvidedProps = {
  workId: string;
  step: number;
  no: number;
  type: 'question';
  segment: string;
  title: string;
  body: string;
  resultType: StepResultTypes;
  skipCondition: string;
  addGuideCondition: string;
  addGuidebody: string;
  outputCondition: number;
  deviceId: string;
  videos?: ResultVideo[];
  voice?: ResultVoice;
  editor: string;
  editorName: string;
  updatedAt: number;
  version: number;
};

export type StepQueryParams = QueryParams & { reportId: string; workId: string };

export type ListVideoQueryParams = QueryParams & { reportId: string; workId: string; step: number };

export type VoiceQueryParams = QueryParams & { reportId: string; workId: string; step: number };

export type Step = StepOptionalProps & StepProvidedProps;

export type ResultReportLabels = {
  attendanceDateAndTime: string;
  engineer: string;
  customer: string;
  continue: string;
  summary: string;
};

export class Steps extends ImmutableMap<string, Step> {
  constructor(steps?: Step[] | Iterable<Step>) {
    super((step: Step) => `${step.workId}_${step.step}`, steps);
  }

  protected clone(steps?: Step[] | Iterable<Step>): this {
    return new Steps(steps) as this;
  }
}
