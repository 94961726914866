import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

export type SnapshotStatus = 'shooting' | 'success' | 'missed';

type SnapshotRequiredProps = {
  to: string;
};

type SnapshotOptionalProps = object;

type SnapshotProvidedProps = {
  snapshotId: string;
  channelId: string;
  name: string;
  from: string;
  status: SnapshotStatus;
  createdAt: number;
  updatedAt: number;
  version: number;
};

export type SnapshotQueryParams = QueryParams;

export type SnapshotCreateParams = SnapshotRequiredProps & SnapshotOptionalProps;

export type Snapshot = SnapshotRequiredProps & SnapshotOptionalProps & SnapshotProvidedProps;

export class Snapshots extends ImmutableMap<string, Snapshot> {
  constructor(snapshots?: Snapshot[] | Iterable<Snapshot>) {
    super((snapshot: Snapshot) => snapshot.snapshotId, snapshots);
  }

  protected clone(snapshots?: Snapshot[] | Iterable<Snapshot>): this {
    return new Snapshots(snapshots) as this;
  }

  override set(snapshot: Snapshot): this {
    if (snapshot.status !== 'shooting') {
      return super.delete(snapshot.snapshotId);
    }
    return super.set(snapshot);
  }
}
