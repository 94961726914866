import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Share, ShareCreateParams, ShareQueryParams, SharedContent } from '../models/share.model';
import { ShareGateway } from '../usecases/share.gateway';

@Injectable()
export class ShareService extends ShareGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listShares(params: ShareQueryParams): Observable<ListResponse<SharedContent>> {
    return this._http.get<ListResponse<SharedContent>>('api/rss/shares', { params });
  }

  createShare(params: ShareCreateParams): Observable<ListResponse<Share>> {
    return this._http.post<ListResponse<Share>>('api/rss/shares', params);
  }

  deleteShare(shareKey: string, groupId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/shares/${shareKey}/groups/${groupId}`);
  }
}
