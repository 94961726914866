import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, partition } from 'rxjs';
import { delayWhen, filter, finalize, map, repeatWhen, takeUntil } from 'rxjs/operators';

import { AuthUsecase, DistinctSubject, WebSocketUsecase, isString, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { ArchiveCombineVideo, ArchiveMeasurement, ArchiveVideo } from '../models/archive.model';
import { Message } from '../models/message.model';
import { ShareCreateParams, ShareQueryParams, SharedArchiveContent, SharedContent } from '../models/share.model';
import { Snapshot } from '../models/snapshot.model';
import { ArchiveGateway } from '../usecases/archive.gateway';
import { MessageGateway } from '../usecases/message.gateway';
import { ShareGateway } from '../usecases/share.gateway';
import { SharedArchiveUsecase } from '../usecases/shared-archive.usecase';

@Injectable()
export class SharedArchiveInteractor extends SharedArchiveUsecase {
  get contents$(): Observable<SharedArchiveContent[]> {
    return this._contents.pipe(
      map(contents =>
        contents.map(({ type, record, ...surplus }) =>
          type === 'channel'
            ? {
                ...surplus,
                type,
                record: {
                  ...record,
                  videos$: this.listArchiveVideos(record.channelId),
                  combineVideos$: this.listArchiveCombineVideos(record.channelId),
                  messages$: this.listMessages(record.channelId),
                  snapshots$: this.listSnapshots(record.channelId),
                  measurements$: this.listArchiveMeasurements(record.channelId),
                },
              }
            : {
                ...surplus,
                type,
                record,
              },
        ),
      ),
    );
  }
  get group$(): Observable<string | null> {
    return this._group;
  }

  private readonly _contents = new DistinctSubject<SharedContent[]>([]);
  private readonly _group = new DistinctSubject<string | null>(null);

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _archiveGateway: ArchiveGateway,
    private _messageGateway: MessageGateway,
    private _shareGateway: ShareGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._group.next(null);
    });

    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    this._group
      .pipe(
        filter(isString),
        takeUntil(close$),
        finalize(() => this._contents.next([])),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => open$))),
      )
      .subscribe(groupId => {
        const queryParams: ShareQueryParams = { groupId };
        recursiveQuery(params => this._shareGateway.listShares(params), queryParams).subscribe(contents => this._contents.next(contents));
      });
  }

  changeGroup(groupId: string | null): void {
    this._group.next(groupId);
  }

  reload(): void {
    const group = this._group.value;
    if (group) {
      this._group.next(null);
      this._group.next(group);
    }
  }

  createShare(params: ShareCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._shareGateway.createShare(params).subscribe({
      next: () => {
        if (params.groups.every(groupId => this._group.value !== groupId)) {
          return;
        }
        this.reload();
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  deleteShare(shareKey: string, groupId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._shareGateway.deleteShare(shareKey, groupId).subscribe({
      next: () => {
        if (this._group.value !== groupId) {
          return;
        }
        const contents = this._contents.value.filter(content => content.shareKey !== shareKey);
        this._contents.next([...contents]);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  private listArchiveVideos(channelId: string): Observable<ArchiveVideo[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveVideos(channelId, params), {});
  }

  private listArchiveCombineVideos(channelId: string): Observable<ArchiveCombineVideo[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveCombineVideos(channelId, params), {});
  }

  private listMessages(channelId: string): Observable<Message[]> {
    return recursiveQuery(params => this._messageGateway.listMessages(channelId, params), {});
  }

  private listSnapshots(channelId: string): Observable<Snapshot[]> {
    return recursiveQuery(params => this._archiveGateway.listSnapshots(channelId, params), {});
  }

  private listArchiveMeasurements(channelId: string): Observable<ArchiveMeasurement[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveMeasurements(channelId, params), {});
  }
}
