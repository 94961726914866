import { Observable } from 'rxjs';

import { Group, GroupCreateParams, GroupQueryParams, GroupUpdateParams } from '../models/group.model';
import { ListResponse } from '../models/utility.model';

export abstract class GroupGateway {
  abstract listGroups(params: GroupQueryParams): Observable<ListResponse<Group>>;
  abstract createGroup(params: GroupCreateParams): Observable<Group>;
  abstract updateGroup(groupId: string, params: GroupUpdateParams): Observable<Group>;
  abstract deleteGroup(groupId: string): Observable<void>;
}
