import { ImmutableMap, Prop, QueryParams, isBoolean, isObject, isString } from './utility.model';

export const MAX_SUPPORTER_COUNT = 10;
export type Operation = 'add' | 'remove';

type SupportTeamRequiredProps = {
  name: string;
  groupId: string;
  supporters: string[];
};

type SupportTeamOptionalProps = object;

type SupportTeamProvidedProps = {
  teamId: string;
  updatedAt: number;
  version: number;
};

export type SupportTeamQueryParams = QueryParams;

export type SupportTeamCreateParams = SupportTeamRequiredProps & SupportTeamOptionalProps & { groupDefault: boolean };

export type SupportTeamUpdateParams = Partial<Omit<SupportTeamRequiredProps, 'groupId'>> &
  SupportTeamOptionalProps & { groupDefault: boolean };

export type SupportTeamsUpdateParams = { userId: string; operation: Operation };

export type SupportTeam = SupportTeamRequiredProps & SupportTeamOptionalProps & SupportTeamProvidedProps;
export type SupportTeamCallees = { callees: string[] };

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isSupportTeamCreateParams(value: unknown): value is SupportTeamCreateParams {
  const sProps: Prop<SupportTeamCreateParams, string>[] = ['name', 'groupId'];
  return (
    isObject(value) &&
    (sProps.every(prop => isString(value[prop])) || isBoolean(value['groupDefault']) || isStringArray(value['supporters']))
  );
}

export function isSupportTeamUpdateParams(value: unknown): value is SupportTeamUpdateParams {
  const sProps: Prop<SupportTeamUpdateParams, string>[] = ['name'];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) || isBoolean(value['groupDefault']) || isStringArray(value['supporters']))
  );
}

export class SupportTeams extends ImmutableMap<string, SupportTeam> {
  constructor(supportTeams?: SupportTeam[] | Iterable<SupportTeam>) {
    super((supportTeam: SupportTeam) => supportTeam.teamId, supportTeams);
  }

  protected clone(supportTeams?: SupportTeam[] | Iterable<SupportTeam>): this {
    return new SupportTeams(supportTeams) as this;
  }
}
