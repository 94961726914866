import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Record, RecordQueryParams, RecordUpdateParams } from '../models/record.model';
import { RecordGateway } from '../usecases/record.gateway';

@Injectable()
export class RecordService extends RecordGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listRecords(params: RecordQueryParams): Observable<ListResponse<Record>> {
    return this._http.get<ListResponse<Record>>('api/rss/records', { params });
  }

  getRecord(recordId: string): Observable<Record> {
    return this._http.get<Record>(`api/rss/records/${recordId}`);
  }

  updateRecord(recordId: string, params: RecordUpdateParams): Observable<Record> {
    return this._http.put<Record>(`api/rss/records/${recordId}`, params);
  }

  getRecordVideo(recordId: string, videoId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/records/${recordId}/videos/${videoId}`);
  }

  deleteRecordVideo(recordId: string, videoId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/records/${recordId}/videos/${videoId}`);
  }
}
