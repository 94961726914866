import { Observable } from 'rxjs';

import { Period, UserTimezone } from '@daikin-tic/dxone-com-lib';

import { Reports } from '../models/report.model';

export abstract class ReportUsecase {
  abstract get reports$(): Observable<Reports>;
  abstract get period$(): Observable<Period>;
  abstract get workUpdateNotice$(): Observable<void>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;
}
