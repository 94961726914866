import { Injectable, NgZone } from '@angular/core';
import { marker as T } from '@biesbjerg/ngx-translate-extract-marker';
import dayjs from 'dayjs';
import { AsyncSubject, BehaviorSubject, EMPTY, Observable, combineLatest, concat, from, interval, noop, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, mergeMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ConnectionOptions, ConnectionPublisher, DataChannelMessageEvent, default as Sora } from 'sora-js-sdk';

import {
  AuthUsecase,
  DistinctSubject,
  MediaDeviceError,
  NeverError,
  ProgressUsecase,
  SupportTeamGateway,
  UA_DEVICE_TYPE,
  WebSocketSyncData,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import { CHANNEL_CAPACITY, Channel, ChannelCreateParams, ChannelQueryParams, Channels, isChannel } from '../models/channel.model';
import { Connection, Connections } from '../models/connection.model';
import { Measurement } from '../models/measurement.model';
import { Message, MessageCreateParams, MessageStatus, Messages } from '../models/message.model';
import { Offer, OfferUpdateParams, Offers } from '../models/offer.model';
import { Snapshot, Snapshots } from '../models/snapshot.model';
import { WebRTCStat } from '../models/stat.model';
import { MIMI_ASR_LANGUAGE, SpeechStatus } from '../models/transcribe.model';
import { AudioUsecase } from '../usecases/audio.usecase';
import { ChannelGateway } from '../usecases/channel.gateway';
import { ConnectionGateway } from '../usecases/connection.gateway';
import { MessageGateway } from '../usecases/message.gateway';
import { OfferGateway } from '../usecases/offer.gateway';
import { RemoteSupportUsecase } from '../usecases/remote-support.usecase';
import { SnapshotGateway } from '../usecases/snapshot.gateway';
import { TranscribeUsecase } from '../usecases/transcribe.usecase';

const convertStatus = (status?: SpeechStatus): MessageStatus => {
  switch (status) {
    case 'recog-in-progress':
      return 'in-progress';
    case 'recog-finished':
      return 'finished';
    default:
      return 'failed';
  }
};

const DECODER = new TextDecoder();

const DISPLAY_DEVICE_ID = 'screen-share';
const DISPLAY_DEVICE_INFO: MediaDeviceInfo = {
  deviceId: DISPLAY_DEVICE_ID,
  groupId: '',
  kind: 'videoinput',
  label: T('core.remote-support.label.screen-share'),
  toJSON: () => JSON.stringify(DISPLAY_DEVICE_INFO),
};

const MEASUREMENT_EMIT_THROTTLE_MS = 1_000;
const MEASUREMENT_EMIT_DEBOUNCE_MS = 100;

@Injectable()
export class RemoteSupportInteractor extends RemoteSupportUsecase {
  get channels$(): Observable<Channels> {
    return this._channels.pipe(map(channels => channels.filter('status', 'active')));
  }
  get connections$(): Observable<Connections> {
    return this._connections;
  }
  get localStream$(): Observable<(Connection & { stream: MediaStream }) | undefined> {
    return this._localStream;
  }
  get remoteStreams$(): Observable<MediaStream[]> {
    return this._remoteStreams;
  }
  get offers$(): Observable<Offers> {
    return this._offers;
  }
  get messages$(): Observable<Messages> {
    return this._messages;
  }
  get snapshots$(): Observable<Snapshots> {
    return this._snapshots;
  }
  get activeStream$(): Observable<MediaStream | undefined> {
    return this._activeStream;
  }
  get volumeMuted$(): Observable<boolean> {
    return this._volumeMuted;
  }
  get audioEnabled$(): Observable<boolean> {
    return this._audioEnabled;
  }
  get videoEnabled$(): Observable<boolean> {
    return this._videoEnabled;
  }
  get videoDevices$(): Observable<MediaDeviceInfo[]> {
    return this._videoDevices;
  }
  get activeDevice$(): Observable<string> {
    return this._activeDevice;
  }
  get missedCalls$(): Observable<number> {
    return combineLatest([
      this._offers,
      this._lastOfferAt,
      this._authUsecase.authState$.pipe(map(({ user }) => user?.attributes?.sub)),
    ]).pipe(
      map(
        ([offers, lastOfferAt, userId]) =>
          offers
            .filter('toUser', toUser => toUser === userId)
            .filter('createdAt', createdAt => createdAt > lastOfferAt)
            .filter('status', 'missed').size,
      ),
    );
  }
  get measurements$(): Observable<Measurement[]> {
    return this._measurements.pipe(
      throttleTime(MEASUREMENT_EMIT_THROTTLE_MS - MEASUREMENT_EMIT_DEBOUNCE_MS),
      debounceTime(MEASUREMENT_EMIT_DEBOUNCE_MS),
      map(measurement => [...measurement.values()]),
    );
  }

  private readonly _channels = new DistinctSubject<Channels>(new Channels());
  private readonly _connections = new DistinctSubject<Connections>(new Connections());
  private readonly _localStream = new DistinctSubject<(Connection & { stream: MediaStream }) | undefined>(undefined);
  private readonly _remoteStreams = new BehaviorSubject<MediaStream[]>([]);
  private readonly _offers = new DistinctSubject<Offers>(new Offers());
  private readonly _messages = new DistinctSubject<Messages>(new Messages());
  private readonly _snapshots = new DistinctSubject<Snapshots>(new Snapshots());
  private readonly _activeStream = new DistinctSubject<MediaStream | undefined>(undefined);
  private readonly _volumeMuted = new DistinctSubject<boolean>(false);
  private readonly _audioEnabled = new DistinctSubject<boolean>(true);
  private readonly _videoEnabled = new DistinctSubject<boolean>(true);
  private readonly _videoDevices = new BehaviorSubject<MediaDeviceInfo[]>([]);
  private readonly _activeDevice = new DistinctSubject<string>('');
  private readonly _lastOfferAt = new DistinctSubject<number>(dayjs().unix());
  private readonly _measurements = new BehaviorSubject<Map<string, Measurement>>(new Map());

  private readonly _statInterval = new DistinctSubject<number>(0);

  private readonly _audioGain: GainNode;
  private readonly _audioNodes = new Map<string, AudioNode>();

  private readonly _sessionPool = new Map<string, string>();

  private _sendrecv?: ConnectionPublisher;

  constructor(
    private _zone: NgZone,
    private _audioUsecase: AudioUsecase,
    private _authUsecase: AuthUsecase,
    private _progressUsecase: ProgressUsecase,
    private _transcribeUsecase: TranscribeUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _channelGateway: ChannelGateway,
    private _connectionGateway: ConnectionGateway,
    private _messageGateway: MessageGateway,
    private _offerGateway: OfferGateway,
    private _snapshotGateway: SnapshotGateway,
    private _supportTeamGateway: SupportTeamGateway,
  ) {
    super();
    this._audioGain = this._audioUsecase.context.createGain();
    this._audioGain.connect(this._audioUsecase.context.destination);

    this.volumeMuted$.subscribe(muted => this._audioGain.gain.setValueAtTime(muted ? 0 : 1, this._audioUsecase.context.currentTime));
    combineLatest([this.localStream$, this.audioEnabled$]).subscribe(([localStream, audioEnabled]) => {
      if (localStream) {
        const { channelId, connectionId, stream } = localStream;
        stream.getAudioTracks()[0].enabled = audioEnabled;
        this._connectionGateway.updateConnectionAudio(channelId, connectionId, audioEnabled).subscribe();
      }
    });
    combineLatest([this.localStream$, this.videoEnabled$]).subscribe(([localStream, videoEnabled]) => {
      if (localStream) {
        const { channelId, connectionId, stream } = localStream;
        stream.getVideoTracks()[0].enabled = videoEnabled;
        this._connectionGateway.updateConnectionVideo(channelId, connectionId, videoEnabled).subscribe();
      }
    });

    this._transcribeUsecase.speech$
      .pipe(
        concatMap(speech => {
          const { channelId, connectionId } = this._localStream.value || {};
          const { sessionId, text, lang, timestamp } = speech;
          const status = convertStatus(speech.status);

          const create = (arg1: string, arg2: string) =>
            this._messageGateway.createMessage(arg1, { connectionId: arg2, status, message: text, lang, timestamp }).pipe(
              tap(message => (message.status === 'in-progress' ? this._sessionPool.set(sessionId, message.messageId) : noop)),
              catchError(() => EMPTY),
            );
          const update = (arg1: string, arg2: string) =>
            this._messageGateway.updateMessage(arg1, arg2, { status, message: text }).pipe(
              tap(message => (message.status !== 'in-progress' ? this._sessionPool.delete(sessionId) : noop)),
              catchError(() => EMPTY),
            );
          const deletes = (arg1: string) =>
            Array.from(this._sessionPool).map(([key, value]) =>
              this._messageGateway.updateMessage(arg1, value, { status: 'failed' }).pipe(
                tap(() => this._sessionPool.delete(key)),
                catchError(() => EMPTY),
              ),
            );

          if (channelId && connectionId && (text || status === 'failed')) {
            const messageId = this._sessionPool.get(sessionId);
            return messageId ? update(channelId, messageId) : concat(...deletes(channelId), create(channelId, connectionId));
          }
          if (channelId) {
            return concat(...deletes(channelId));
          }
          return EMPTY;
        }),
      )
      .subscribe(message => this._messages.next(this._messages.value.set(message)));

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'channel'),
        map(({ data }) => data as WebSocketSyncData<Channel>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
            this._channels.next(this._channels.value.set(data.payload));
            break;
          case 'update': {
            const channel = data.payload;
            this._channels.next(this._channels.value.set(channel));
            if (channel.status !== 'active') {
              const connections = this._connections.value
                .values()
                .filter(({ channelId }) => channelId === channel.channelId)
                .reduce((acc, cur) => acc.delete(cur.connectionId), this._connections.value);
              this._connections.next(connections);
            }
            break;
          }
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'connection'),
        map(({ data }) => data as WebSocketSyncData<Connection>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            if (data.payload.connectionId === this._localStream.value?.connectionId && data.payload.exitReason === 'kick') {
              this.leave();
            }
            this._connections.next(this._connections.value.set(data.payload));
            break;
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'offer'),
        map(({ data }) => data as WebSocketSyncData<Offer>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._offers.next(this._offers.value.set(data.payload));
            break;
          case 'delete':
            this._offers.next(this._offers.value.delete(data.payload.offerId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'message'),
        map(({ data }) => data as WebSocketSyncData<Message>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._messages.next(this._messages.value.set(data.payload));
            break;
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'snapshot'),
        map(({ data }) => data as WebSocketSyncData<Snapshot>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._snapshots.next(this._snapshots.value.set(data.payload));
            break;
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });

    this._statInterval
      .pipe(
        concatMap(period => {
          return period > 0 ? interval(period * 1000).pipe(takeUntil(this._statInterval.pipe(filter(p => p <= 0)))) : of(period);
        }),
        concatMap(() => {
          const sender = this._sendrecv?.pc?.getSenders().find(({ track }) => track && track.kind === 'video');
          return sender?.getStats() || EMPTY;
        }),
        concatMap(statsReport => {
          return new Observable<RTCOutboundRtpStreamStats>(subscriber => {
            statsReport.forEach((stats: RTCStats) => {
              if (stats.type === 'outbound-rtp') {
                subscriber.next(stats as RTCOutboundRtpStreamStats);
              }
            });
            subscriber.complete();
          });
        }),
        map<RTCOutboundRtpStreamStats, WebRTCStat>(stats => ({
          connectionId: this._localStream.value?.connectionId ?? '',
          outboundPliCount: stats.pliCount ?? 0,
          outboundNackCount: stats.nackCount ?? 0,
          updatedAt: Math.floor((stats.timestamp ?? Date.now()) / 1000),
        })),
      )
      .subscribe(stats => {
        this._webSocketUsecase.sendMessage({
          action: 'stat',
          data: {
            reason: 'in-progress',
            source: 'webrtc',
            payload: stats,
          },
        });
      });
  }

  standby(channelName: string, spotlight: boolean): Observable<never> {
    const result = new AsyncSubject<never>();
    from(this.connect({ name: channelName, spotlight })).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  call(channelName: string, spotlight: boolean, to: string): Observable<never> {
    const result = new AsyncSubject<never>();
    from(this.connect({ name: channelName, spotlight }, to)).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  calls(channelName: string, spotlight: boolean, teamId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    from(this.connect({ name: channelName, spotlight }, teamId, true)).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  enter(channelId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    const channel = this._channels.value.get(channelId);
    if (channel) {
      from(this.connect(channel)).subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    } else {
      result.error(new Error('Channel is not exist.'));
    }
    return result.asObservable();
  }

  dismiss(channelId: string, connectionId?: string): Observable<never> {
    const result = new AsyncSubject<never>();
    if (connectionId) {
      this._connectionGateway.deleteConnection(channelId, connectionId).subscribe({
        next: () => this._connections.next(this._connections.value.delete(connectionId)),
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    } else {
      this._channelGateway.deleteChannel(channelId).subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    }
    return result.asObservable();
  }

  leave(): Observable<never> {
    const result = new AsyncSubject<never>();
    from(this.disconnect()).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  invite(channelId: string, to: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._offerGateway.createOffer({ channelId, to }).subscribe({
      next: offer => this._offers.next(this._offers.value.set(offer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  inviteTeam(channelId: string, teamId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._supportTeamGateway
      .getSupportTeamCallees(teamId)
      .pipe(mergeMap(({ callees }) => this._offerGateway.createOfferMultiple({ channelId, to: callees })))
      .subscribe({
        next: ({ items }) => {
          const offers = items.reduce((acc, cur) => acc.set(cur), this._offers.value);
          this._offers.next(offers);
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  accept(offerId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._offerGateway.updateOffer(offerId, { status: 'accepted' }).subscribe({
      next: offer => this._offers.next(this._offers.value.set(offer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reject(offerId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._offerGateway.updateOffer(offerId, { status: 'rejected' }).subscribe({
      next: offer => this._offers.next(this._offers.value.set(offer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  failed(offerId: string, reason?: string): Observable<never> {
    const result = new AsyncSubject<never>();
    const params: OfferUpdateParams = { status: 'failed', reason: reason || 'failed' };
    this._offerGateway.updateOffer(offerId, params).subscribe({
      next: offer => this._offers.next(this._offers.value.set(offer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  sendMessage(channelId: string, params: MessageCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._messageGateway.createMessage(channelId, params).subscribe({
      next: message => this._messages.next(this._messages.value.set(message)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  takeSnapshot(channelId: string, to: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._snapshotGateway.createSnapshot(channelId, { to }).subscribe({
      next: snapshot => this._snapshots.next(this._snapshots.value.set(snapshot)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  setActiveStream(stream: MediaStream): void {
    this._activeStream.next(stream);
  }

  setVolumeMuted(muted: boolean): void {
    this._volumeMuted.next(muted);
  }

  setAudioEnabled(enabled: boolean): void {
    this._audioEnabled.next(enabled);
  }

  setVideoEnabled(enabled: boolean): void {
    this._videoEnabled.next(enabled);
  }

  setActiveDevice(activeDevice: string): void {
    const { stream } = this._localStream.value || {};
    const prevTrack = stream?.getVideoTracks()[0];
    if (stream && prevTrack && this._sendrecv) {
      const sendrecv = this._sendrecv;
      const replaceDevice = async (): Promise<string> => {
        await sendrecv.stopVideoTrack(stream);
        stream.getVideoTracks().forEach(track => track.stop());

        const originalStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId: activeDevice } });
        const videoTrack = originalStream.getVideoTracks()[0];
        videoTrack.enabled = this._videoEnabled.value;

        await sendrecv.replaceVideoTrack(stream, videoTrack);
        stream.addTrack(originalStream.getVideoTracks()[0]);

        return originalStream.getVideoTracks()[0]?.getSettings().deviceId || '';
      };
      const replaceDisplay = async (): Promise<string> => {
        const originalStream = await navigator.mediaDevices.getDisplayMedia({ audio: false, video: true });
        const videoTrack = originalStream.getVideoTracks()[0];
        videoTrack.enabled = this._videoEnabled.value;
        videoTrack.onended = () => this.setActiveDevice(this._videoDevices.value[0].deviceId);

        await sendrecv.stopVideoTrack(stream);
        stream.getVideoTracks().forEach(track => track.stop());

        await sendrecv.replaceVideoTrack(stream, videoTrack);
        stream.addTrack(originalStream.getVideoTracks()[0]);

        return DISPLAY_DEVICE_ID;
      };
      (activeDevice === DISPLAY_DEVICE_ID ? replaceDisplay : replaceDevice)()
        .then(deviceId => this._activeDevice.next(deviceId))
        .catch(err => console.log(err));
    } else {
      this._activeDevice.next(activeDevice);
    }
  }

  clearMissedCalls(): void {
    this._lastOfferAt.next(dayjs().unix());
  }

  private onSignIn(): void {
    const queryParams: ChannelQueryParams = { status: ['standby', 'active'] };
    recursiveQuery(params => this._channelGateway.listChannels(params), queryParams).subscribe(channels => {
      const connections = channels.reduce((acc, channel) => {
        acc.push(...channel.connections);
        return acc;
      }, [] as Connection[]);
      this._channels.next(new Channels(channels));
      this._connections.next(new Connections(connections));
    });

    recursiveQuery(params => this._offerGateway.listOffers(params), {}).subscribe(offers => {
      this._offers.next(new Offers(offers));
    });
  }

  private onSignOut(): void {
    this.leave().subscribe({
      complete: () => {
        this._channels.next(new Channels());
        this._connections.next(new Connections());
        this._offers.next(new Offers());
        this._volumeMuted.next(false);
        this._audioEnabled.next(true);
        this._videoEnabled.next(true);
      },
    });
  }

  private onDeviceChange(): void {
    navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
      const audioDevices = mediaDevices.filter(device => device.kind === 'audioinput');
      const videoDevices = mediaDevices.filter(device => device.kind === 'videoinput');
      if (!audioDevices.length || !videoDevices.length) {
        this.leave();
        return;
      }
      if (UA_DEVICE_TYPE === 'pc') {
        videoDevices.push(DISPLAY_DEVICE_INFO);
      }
      if (!videoDevices.find(device => device.deviceId === this._activeDevice.value)) {
        this.setActiveDevice(videoDevices[0].deviceId);
      }
      this._videoDevices.next(videoDevices);
    });
  }

  private onAddTrack(event: RTCTrackEvent): void {
    const stream = event.streams[0];
    const remoteStreams = this._remoteStreams.value;
    const index = remoteStreams.findIndex(remoteStream => remoteStream.id === stream.id);
    if (index < 0) {
      const audioNode = this._audioUsecase.context.createMediaStreamSource(stream);
      audioNode.connect(this._audioGain);
      this._audioNodes.set(stream.id, audioNode);
      this._remoteStreams.next([...remoteStreams, stream]);

      // Temporarily connect to audio because the audio stream does not flow on Android Chrome.
      new Audio().srcObject = stream;
    }
  }

  private onRemoveTrack(event: MediaStreamTrackEvent): void {
    const stream = event.target as MediaStream;
    const remoteStreams = this._remoteStreams.value;
    const index = remoteStreams.findIndex(remoteStream => remoteStream.id === stream.id);
    if (index >= 0) {
      this._audioNodes.get(stream.id)?.disconnect();
      this._audioNodes.delete(stream.id);
      remoteStreams.splice(index, 1);
      this._remoteStreams.next(remoteStreams);
    }
    if (this._activeStream.value === stream) {
      this._activeStream.next(undefined);
    }
  }

  private onDisconnected(): void {
    const { channelId, connectionId } = this._sendrecv || {};
    if (channelId && connectionId) {
      this._connectionGateway.deleteConnection(channelId, connectionId).subscribe(() => {
        this._connections.next(this._connections.value.delete(connectionId));
      });
    }
    this._sendrecv = undefined;
    this._transcribeUsecase.stop();
    this._messages.next(new Messages());
    this._localStream.next(undefined);
    this._remoteStreams.next([]);
    this._activeStream.next(undefined);
    for (const audio of this._audioNodes.values()) {
      audio.disconnect();
    }
    this._audioNodes.clear();
    this._videoDevices.next([]);
    this._activeDevice.next('');
    this._measurements.next(new Map());
    navigator.mediaDevices.ondevicechange = null;
  }

  private onReceiveMessage(event: DataChannelMessageEvent): void {
    const measurement = JSON.parse(DECODER.decode(event.data)) as Measurement;
    this._measurements.next(
      this._measurements.value.set(`${measurement.userId}_${measurement.model}_${measurement.serialNo}`, measurement),
    );
  }

  private async connect(channel: Channel | ChannelCreateParams, inviteTo?: string, isMultiple = false): Promise<void> {
    const progressId = this._progressUsecase.show();
    await this.disconnect();
    if (isChannel(channel) && this.isOverCapacity(channel)) {
      this._progressUsecase.dismiss(progressId);
      throw new Error('Channel is over capacity.');
    }
    const stream = await navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const hasVideo = devices.find(device => device.kind === 'videoinput');
        const hasAudio = devices.find(device => device.kind === 'audioinput');
        if (!hasVideo && !hasAudio) {
          return Promise.reject(new MediaDeviceError('device'));
        } else if (!hasVideo) {
          return Promise.reject(new MediaDeviceError('camera'));
        } else if (!hasAudio) {
          return Promise.reject(new MediaDeviceError('mic'));
        }
        return navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            echoCancellationType: 'system',
          },
          video: {
            facingMode: 'environment',
          },
        });
      })
      .catch(err => {
        this._progressUsecase.dismiss(progressId);
        if (err instanceof MediaDeviceError) {
          throw err;
        } else {
          throw new MediaDeviceError('permit');
        }
      });

    const createChannel = async (params: ChannelCreateParams) =>
      await this._channelGateway
        .createChannel(params)
        .toPromise()
        .catch(err => {
          stream.getTracks().forEach(track => track.stop());
          this._progressUsecase.dismiss(progressId);
          throw err;
        });

    const { channelId, endpointUrl, spotlight, transcribe, measurement, statInterval } = isChannel(channel)
      ? channel
      : await createChannel(channel);

    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = mediaDevices.filter(mediaDevice => mediaDevice.kind === 'videoinput');
    if (UA_DEVICE_TYPE === 'pc') {
      videoDevices.push(DISPLAY_DEVICE_INFO);
    }
    this._videoDevices.next(videoDevices);
    this._activeDevice.next(stream.getVideoTracks()[0]?.getSettings().deviceId || '');
    navigator.mediaDevices.ondevicechange = () => this._zone.run(this.onDeviceChange.bind(this));

    stream.getAudioTracks()[0].enabled = this._audioEnabled.value;
    stream.getVideoTracks()[0].enabled = this._videoEnabled.value;

    const { accessToken } = await this._authUsecase.payload$.toPromise();
    const metadata = { token: accessToken, options: transcribe ? MIMI_ASR_LANGUAGE : undefined };
    const options: ConnectionOptions = {
      multistream: true,
      videoCodecType: 'VP8',
      connectionTimeout: 20000,
      dataChannelSignaling: true,
      ignoreDisconnectWebSocket: true,
    };
    if (spotlight) {
      options.simulcast = true;
      options.spotlight = true;
      options.spotlightFocusRid = 'r0';
      options.spotlightUnfocusRid = 'r0';
    }
    if (measurement) {
      options.dataChannels = [
        { label: '#TESTO', direction: 'recvonly' },
        { label: '#HIOKI', direction: 'recvonly' },
      ];
    }
    const sendrecv = Sora.connection(endpointUrl).sendrecv(channelId, metadata, options);

    try {
      sendrecv.on('track', this.onAddTrack.bind(this));
      sendrecv.on('removetrack', this.onRemoveTrack.bind(this));
      sendrecv.on('disconnect', this.onDisconnected.bind(this));
      sendrecv.on('message', this.onReceiveMessage.bind(this));
      await sendrecv.connect(stream);
    } catch (err) {
      stream.getTracks().forEach(track => track.stop());
      this._progressUsecase.dismiss(progressId);
      throw err;
    }

    try {
      const connectionId = sendrecv.connectionId || '';
      const connection = await this._connectionGateway
        .createConnection(channelId, { connectionId, audio: this._audioEnabled.value, video: this._videoEnabled.value })
        .toPromise();
      this._connections.next(this._connections.value.set(connection));
      this._localStream.next({ ...connection, stream });

      if (inviteTo) {
        if (isMultiple) {
          const { callees } = await this._supportTeamGateway.getSupportTeamCallees(inviteTo).toPromise();
          const { items } = await this._offerGateway.createOfferMultiple({ channelId, to: callees }).toPromise();
          const offers = items.reduce((acc, cur) => acc.set(cur), this._offers.value);
          this._offers.next(offers);
        } else {
          const offer = await this._offerGateway.createOffer({ channelId, to: inviteTo }).toPromise();
          this._offers.next(this._offers.value.set(offer));
        }
      }

      if (transcribe) {
        const messages = await recursiveQuery(params => this._messageGateway.listMessages(channelId, params), {}).toPromise();
        this._messages.next(new Messages(messages));
        this._transcribeUsecase.start(stream);
      }

      if (statInterval > 0) {
        this._statInterval.next(statInterval);
      }
    } catch (err) {
      sendrecv.disconnect();
      this._progressUsecase.dismiss(progressId);
      throw err;
    }
    this._sendrecv = sendrecv;
    this._progressUsecase.dismiss(progressId);
  }

  private async disconnect(): Promise<void> {
    const progressId = this._progressUsecase.show();
    const { channelId, connectionId } = this._sendrecv || {};
    const { stream } = this._localStream.value || {};
    this._statInterval.next(0);
    if (channelId && connectionId) {
      await this._sendrecv?.disconnect();
      await this._connectionGateway.deleteConnection(channelId, connectionId).toPromise().catch(noop);
      this._connections.next(this._connections.value.delete(connectionId));
      stream?.getTracks().forEach(track => track.stop());
    } else if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    this._sendrecv = undefined;
    this._transcribeUsecase.stop();
    this._messages.next(new Messages());
    this._snapshots.next(new Snapshots());
    this._localStream.next(undefined);
    this._remoteStreams.next([]);
    this._activeStream.next(undefined);
    for (const audio of this._audioNodes.values()) {
      audio.disconnect();
    }
    this._audioNodes.clear();
    this._videoDevices.next([]);
    this._activeDevice.next('');
    this._measurements.next(new Map());
    navigator.mediaDevices.ondevicechange = null;
    this._progressUsecase.dismiss(progressId);
  }

  private isOverCapacity(channel: Channel): boolean {
    const connections = this._connections.value
      .values()
      .filter(({ channelId, status }) => channelId === channel?.channelId && status === 'online');
    return connections.length >= CHANNEL_CAPACITY;
  }
}
