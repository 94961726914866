import { Injectable } from '@angular/core';
import { AsyncSubject, EMPTY, Observable, noop } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

import { DistinctSubject, NeverError, WebSocketSyncData, WebSocketUsecase, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { ListVideoQueryParams, Step, StepQueryParams, Steps, VoiceQueryParams } from '../models/step.model';
import { StepGateway } from '../usecases/step.gateway';
import { StepUsecase } from '../usecases/step.usecase';

@Injectable()
export class StepInteractor extends StepUsecase {
  get steps$(): Observable<Steps> {
    return this._steps;
  }

  private readonly _steps = new DistinctSubject<Steps>(new Steps());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _stepGateway: StepGateway,
  ) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? noop : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'achievement'),
        map(({ data }) => data as WebSocketSyncData<Step>),
      )
      .subscribe(data => {
        const step = data.payload as Step;
        switch (data.reason) {
          case 'create':
          case 'update':
            if (this._steps.value.values().some(({ workId }) => workId === step.workId)) {
              this._steps.next(this._steps.value.set(step));
            }
            break;
          case 'delete': {
            this._steps.next(this._steps.value.delete(`${step.workId}_${step.step}`));
            break;
          }
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  listSteps(params: StepQueryParams): Observable<never> {
    this._steps.next(new Steps());
    return recursiveQuery(paramsA => this._stepGateway.listSteps(paramsA), params).pipe(
      tap(steps => this._steps.next(new Steps(steps))),
      concatMap(() => EMPTY),
    );
  }

  listVideos(params: ListVideoQueryParams): Observable<string[]> {
    const result = new AsyncSubject<string[]>();
    this._stepGateway.listVideos(params).subscribe({
      next: ({ items }) => result.next(items),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getVoice(params: VoiceQueryParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._stepGateway.getVoice(params).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  clearSteps(): void {
    this._steps.next(new Steps());
  }

  private onSignOut(): void {
    this._steps.next(new Steps());
  }
}
