import { Inject, Injectable } from '@angular/core';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { Observable } from 'rxjs';

import { ENVIRONMENT, Environment, Progress } from '@daikin-tic/dxone-com-lib';

import { CloudStorage } from '../models/cloud-storage.modal';
import { CloudStorageGateway } from '../usecases/cloud-storage.gateway';

@Injectable()
export class CloudStorageService extends CloudStorageGateway {
  constructor(@Inject(ENVIRONMENT) private _environment: Environment) {
    super();
  }

  upload(storage: CloudStorage, body: Blob): Observable<Progress> {
    const s3 = new S3Client({
      region: this._environment.region,
      credentials: { ...storage.credentials, expiration: new Date(storage.credentials.expiration) },
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const upload = new Upload({ client: s3, params: { Bucket: storage.bucket, Key: storage.key, Body: body } });

    return new Observable(subscriber => {
      upload.on('httpUploadProgress', ({ total = 0, loaded = 0 }) => subscriber.next({ total, loaded }));
      upload.done().then(() => subscriber.complete());
    });
  }
}
