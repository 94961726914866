import { Observable } from 'rxjs';

import { Speech } from '../models/transcribe.model';

export abstract class TranscribeUsecase {
  abstract get speech$(): Observable<Speech>;

  abstract start(stream: MediaStream): void;
  abstract stop(): void;
}
