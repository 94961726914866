import { Observable } from 'rxjs';

import { Channels } from '../models/channel.model';
import { Connection, Connections } from '../models/connection.model';
import { Measurement } from '../models/measurement.model';
import { Message, Messages } from '../models/message.model';
import { Offers } from '../models/offer.model';
import { Snapshots } from '../models/snapshot.model';

export abstract class RemoteSupportUsecase {
  abstract get channels$(): Observable<Channels>;
  abstract get connections$(): Observable<Connections>;
  abstract get localStream$(): Observable<(Connection & { stream: MediaStream }) | undefined>;
  abstract get remoteStreams$(): Observable<MediaStream[]>;
  abstract get offers$(): Observable<Offers>;
  abstract get messages$(): Observable<Messages>;
  abstract get snapshots$(): Observable<Snapshots>;
  abstract get activeStream$(): Observable<MediaStream | undefined>;
  abstract get volumeMuted$(): Observable<boolean>;
  abstract get audioEnabled$(): Observable<boolean>;
  abstract get videoEnabled$(): Observable<boolean>;
  abstract get videoDevices$(): Observable<MediaDeviceInfo[]>;
  abstract get activeDevice$(): Observable<string>;
  abstract get missedCalls$(): Observable<number>;
  abstract get measurements$(): Observable<Measurement[]>;

  abstract standby(channelName: string, spotlight: boolean): Observable<never>;
  abstract call(channelName: string, spotlight: boolean, to: string): Observable<never>;
  abstract calls(channelName: string, spotlight: boolean, to: string): Observable<never>;
  abstract enter(channelId: string): Observable<never>;
  abstract leave(): Observable<never>;
  abstract invite(channelId: string, to: string): Observable<never>;
  abstract inviteTeam(channelId: string, teamId: string): Observable<never>;
  abstract dismiss(channelId: string, connectionId?: string): Observable<never>;
  abstract accept(offerId: string): Observable<never>;
  abstract reject(offerId: string): Observable<never>;
  abstract failed(offerId: string, reason?: string): Observable<never>;
  abstract sendMessage(channelId: string, message: Message): Observable<never>;
  abstract takeSnapshot(channelId: string, to: string): Observable<never>;
  abstract setActiveStream(stream: MediaStream): void;
  abstract setVolumeMuted(muted: boolean): void;
  abstract setAudioEnabled(enabled: boolean): void;
  abstract setVideoEnabled(enabled: boolean): void;
  abstract setActiveDevice(activeDevice: string): void;
  abstract clearMissedCalls(): void;
}
