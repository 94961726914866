import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

export type OfferStatus = 'invited' | 'accepted' | 'rejected' | 'missed' | 'failed';

type OfferRequiredProps = {
  channelId: string;
  to: string;
};

type OfferOptionalProps = {
  reason?: string;
  status: OfferStatus;
};

type OfferProvidedProps = {
  offerId: string;
  from: string;
  fromUser: string;
  toUser: string;
  createdAt: number;
  expiredAt: number;
  updatedAt: number;
  version: number;
};

export type OfferQueryParams = QueryParams;

export type OfferCreateParams = OfferRequiredProps;

export type OfferMultipleCreateParams = Pick<OfferRequiredProps, 'channelId'> & { to: string[] };

export type OfferUpdateParams = OfferOptionalProps;

export type Offer = OfferRequiredProps & OfferOptionalProps & OfferProvidedProps;

export class Offers extends ImmutableMap<string, Offer> {
  constructor(offers?: Offer[] | Iterable<Offer>) {
    super((offer: Offer) => offer.offerId, offers);
  }

  protected clone(offers?: Offer[] | Iterable<Offer>): this {
    return new Offers(offers) as this;
  }
}
