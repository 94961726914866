import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { CloudStorage } from '../models/cloud-storage.modal';
import {
  Reference,
  ReferenceCreateParams,
  ReferenceQueryParams,
  ReferenceUpdateParams,
  ReferenceVideo,
  ReferenceVideoCreateParams,
  ReferenceVideoQueryParams,
  ReferenceVideoUpdateParams,
} from '../models/reference.model';

export abstract class ReferenceGateway {
  abstract listReferences(params: ReferenceQueryParams): Observable<ListResponse<Reference>>;
  abstract createReference(params: ReferenceCreateParams): Observable<Reference>;
  abstract updateReference(referenceId: string, params: ReferenceUpdateParams): Observable<Reference>;
  abstract deleteReference(referenceId: string): Observable<void>;
  abstract listReferenceVideos(referenceId: string, params: ReferenceVideoQueryParams): Observable<ListResponse<ReferenceVideo>>;
  abstract createReferenceVideo(referenceId: string, params: ReferenceVideoCreateParams): Observable<CloudStorage>;
  abstract getReferenceVideo(referenceId: string, videoId: string): Observable<{ url: string }>;
  abstract updateReferenceVideo(referenceId: string, videoId: string, params: ReferenceVideoUpdateParams): Observable<ReferenceVideo>;
  abstract deleteReferenceVideo(referenceId: string, videoId: string): Observable<void>;
}
