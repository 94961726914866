import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Record, RecordQueryParams, RecordUpdateParams } from '../models/record.model';

export abstract class RecordGateway {
  abstract listRecords(params: RecordQueryParams): Observable<ListResponse<Record>>;
  abstract getRecord(recordId: string): Observable<Record>;
  abstract updateRecord(recordId: string, params: RecordUpdateParams): Observable<Record>;
  abstract getRecordVideo(recordId: string, videoId: string): Observable<{ url: string }>;
  abstract deleteRecordVideo(recordId: string, videoId: string): Observable<void>;
}
