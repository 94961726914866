import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Property, PropertyQueryParams } from '../models/property.model';
import { PropertyGateway } from '../usecases/property.gateway';

@Injectable()
export class PropertyService extends PropertyGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listProperties(params: PropertyQueryParams): Observable<ListResponse<Property>> {
    return this._http.get<ListResponse<Property>>('api/rss/properties', { params });
  }
}
