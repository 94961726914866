import { DeviceType, ImmutableMap } from '@daikin-tic/dxone-com-lib';

export type ConnectionStatus = 'online' | 'offline';

export type ConnectionExitReason = 'self' | 'kick' | 'disconnection' | 'schedule';

type ConnectionRequiredProps = {
  connectionId: string;
};

type ConnectionOptionalProps = {
  audio?: boolean;
  video?: boolean;
};

type ConnectionProvidedProps = {
  channelId: string;
  groupId: string;
  deviceId: string;
  type?: DeviceType;
  userId: string;
  name: string;
  status: ConnectionStatus;
  exitReason?: ConnectionExitReason;
  updatedAt: number;
  version: number;
};

export type ConnectionCreateParams = ConnectionRequiredProps & ConnectionOptionalProps;

export type Connection = ConnectionRequiredProps & ConnectionOptionalProps & ConnectionProvidedProps;

export class Connections extends ImmutableMap<string, Connection> {
  constructor(connections?: Connection[] | Iterable<Connection>) {
    super((connection: Connection) => connection.connectionId, connections);
  }

  protected clone(connections?: Connection[] | Iterable<Connection>): this {
    return new Connections(connections) as this;
  }
}
