import { Observable } from 'rxjs';

import { Firmware, FirmwareCreateParams, FirmwareType, FirmwareUpdateParams } from '../models/firmware.model';

export abstract class FirmwareUsecase {
  abstract get firmwares$(): Observable<Firmware[]>;

  abstract createFirmware(type: FirmwareType, params: FirmwareCreateParams): Observable<never>;
  abstract updateFirmware(type: FirmwareType, firmware: string, params: FirmwareUpdateParams): Observable<never>;
  abstract deleteFirmware(type: FirmwareType, firmware: string): Observable<never>;

  abstract reload(): void;
}
