import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Location, LocationQueryParams } from '../models/location.model';
import { ListResponse } from '../models/utility.model';
import { LocationGateway } from '../usecases/location.gateway';

@Injectable()
export class LocationService extends LocationGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listLocations(params: LocationQueryParams): Observable<ListResponse<Location>> {
    return this._http.get<ListResponse<Location>>('api/com/locations', { params });
  }
}
