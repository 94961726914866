import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Tag, TagCreateParams, TagQueryParams } from '../models/tag.model';
import { TagGateway } from '../usecases/tag.gateway';

@Injectable()
export class TagService extends TagGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listTags(params: TagQueryParams): Observable<ListResponse<Tag>> {
    return this._http.get<ListResponse<Tag>>('api/rss/tags', { params });
  }

  createTag(params: TagCreateParams): Observable<Tag> {
    return this._http.post<Tag>('api/rss/tags', params);
  }

  deleteTag(tagId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/tags/${tagId}`);
  }
}
