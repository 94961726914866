import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HIDE_PROGRESS } from '@daikin-tic/dxone-com-lib';

import { Connection, ConnectionCreateParams } from '../models/connection.model';
import { ConnectionGateway } from '../usecases/connection.gateway';

@Injectable()
export class ConnectionService extends ConnectionGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  createConnection(channelId: string, params: ConnectionCreateParams): Observable<Connection> {
    return this._http.post<Connection>(`api/rss/channels/${channelId}/connections`, params);
  }

  updateConnectionAudio(channelId: string, connectionId: string, audio: boolean): Observable<Connection> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<Connection>(`api/rss/channels/${channelId}/connections/${connectionId}`, { audio }, options);
  }

  updateConnectionVideo(channelId: string, connectionId: string, video: boolean): Observable<Connection> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<Connection>(`api/rss/channels/${channelId}/connections/${connectionId}`, { video }, options);
  }

  deleteConnection(channelId: string, connectionId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/channels/${channelId}/connections/${connectionId}`);
  }
}
