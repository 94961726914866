import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Group, GroupCreateParams, GroupQueryParams, GroupUpdateParams } from '../models/group.model';
import { ListResponse } from '../models/utility.model';
import { GroupGateway } from '../usecases/group.gateway';

@Injectable()
export class GroupServie extends GroupGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listGroups(params: GroupQueryParams): Observable<ListResponse<Group>> {
    return this._http.get<ListResponse<Group>>('api/com/groups', { params });
  }

  createGroup(params: GroupCreateParams): Observable<Group> {
    return this._http.post<Group>('api/com/groups', params);
  }

  updateGroup(groupId: string, params: GroupUpdateParams): Observable<Group> {
    return this._http.put<Group>(`api/com/groups/${groupId}`, params);
  }

  deleteGroup(groupId: string): Observable<void> {
    return this._http.delete<void>(`api/com/groups/${groupId}`);
  }
}
