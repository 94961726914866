import { Observable } from 'rxjs';

import { TagCreateParams, Tags } from '../models/tag.model';

export abstract class TagUsecase {
  abstract get tags$(): Observable<Tags>;

  abstract createTag(params: TagCreateParams): Observable<never>;
  abstract deleteTag(tagId: string): Observable<never>;
}
