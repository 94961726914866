import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { AccessPoint, AccessPointCreateParams, AccessPointQueryParams } from '../models/access-point.model';
import { AccessPointGateway } from '../usecases/access-point.gateway';

@Injectable()
export class AccessPointService extends AccessPointGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listAccessPoints(params: AccessPointQueryParams): Observable<ListResponse<AccessPoint>> {
    return this._http.get<ListResponse<AccessPoint>>('api/rss/access-points', { params });
  }

  createAccessPoint(params: AccessPointCreateParams): Observable<AccessPoint> {
    return this._http.post<AccessPoint>('api/rss/access-points', params);
  }

  deleteAccessPoint(accessPointId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/access-points/${accessPointId}`);
  }
}
