import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TrackingDevice, TrackingDeviceQueryParams } from '../models/tracking-device.model';
import { User, UserCreateParams, UserQueryParams, UserUpdateParams } from '../models/user.model';
import { ListResponse } from '../models/utility.model';
import { UserGateway } from '../usecases/user.gateway';

@Injectable()
export class UserService extends UserGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listUsers(params: UserQueryParams): Observable<ListResponse<User>> {
    return this._http.get<ListResponse<User>>('api/com/users', { params });
  }

  createUser(params: UserCreateParams): Observable<User> {
    return this._http.post<User>('api/com/users', params);
  }

  updateUser(userId: string, params: UserUpdateParams): Observable<User> {
    return this._http.put<User>(`api/com/users/${userId}`, params);
  }

  deleteUser(userId: string): Observable<void> {
    return this._http.delete<void>(`api/com/users/${userId}`);
  }

  resendUserInvite(userId: string): Observable<void> {
    return this._http.post<void>(`api/com/users/${userId}/invite`, null);
  }

  listUserTrackingDevices(userId: string, params: TrackingDeviceQueryParams): Observable<ListResponse<TrackingDevice>> {
    return this._http.get<ListResponse<TrackingDevice>>(`api/com/users/${userId}/tracking-devices`, { params });
  }

  deleteUserTrackingDevices(userId: string): Observable<void> {
    return this._http.delete<void>(`api/com/users/${userId}/tracking-devices`);
  }

  updateUserRestriction(userId: string, restriction: { enabled: boolean }): Observable<User> {
    return this._http.put<User>(`api/com/users/${userId}/restriction`, restriction);
  }
}
