import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError, WebSocketSyncData, WebSocketUsecase, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { Tag, TagCreateParams, Tags } from '../models/tag.model';
import { TagGateway } from '../usecases/tag.gateway';
import { TagUsecase } from '../usecases/tag.usecase';

@Injectable()
export class TagInteractor extends TagUsecase {
  get tags$(): Observable<Tags> {
    return this._tags;
  }

  private _tags = new BehaviorSubject<Tags>(new Tags());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _tagGateway: TagGateway,
  ) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'tag'),
        map(({ data }) => data as WebSocketSyncData<Tag>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._tags.next(this._tags.value.set(data.payload));
            break;
          case 'delete':
            this._tags.next(this._tags.value.delete(data.payload.tagId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createTag(params: TagCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._tagGateway.createTag(params).subscribe({
      next: createdTag => this._tags.next(this._tags.value.set(createdTag)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteTag(tagId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._tagGateway.deleteTag(tagId).subscribe({
      next: () => this._tags.next(this._tags.value.delete(tagId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._tagGateway.listTags(params), {}).subscribe(tags => this._tags.next(new Tags(tags)));
  }

  private onSignOut(): void {
    this._tags.next(new Tags());
  }
}
