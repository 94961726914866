import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { WifiNetwork, WifiNetworkCreateParams, WifiNetworkQueryParams } from '../models/wifi-network.model';

export abstract class WifiNetworkGateway {
  abstract listWifiNetworks(params: WifiNetworkQueryParams): Observable<ListResponse<WifiNetwork>>;
  abstract createWifiNetwork(params: WifiNetworkCreateParams): Observable<WifiNetwork>;
  abstract deleteWifiNetwork(wifiNetworkId: string): Observable<void>;
}
