import { Prop, QueryParams, RequiredOnly, isObject, isString } from './utility.model';

export const SOFTWARE_TYPES = ['thinklet'] as const;
export type SoftwareType = (typeof SOFTWARE_TYPES)[number];

type SoftwareRequiredProps = {
  software: string;
  dxoneThinkletApp: string;
  launcher: string;
  mdmClient: string;
  deviceOwner: string;
  uploader: string;
  sound: string;
  sibyl: string;
};

type SoftwareOptionalProps = {
  remarks?: string;
};

type SoftwareProvidedProps = {
  type: SoftwareType;
  updatedAt: number;
};

export type SoftwareQueryParams = QueryParams;

export type SoftwareCreateParams = SoftwareRequiredProps & SoftwareOptionalProps;

export type SoftwareUpdateParams = Required<SoftwareOptionalProps>;

export type Software = SoftwareRequiredProps & SoftwareOptionalProps & SoftwareProvidedProps;

export function isSoftwareCreateParams(value: unknown): value is SoftwareCreateParams {
  const sProps: Prop<RequiredOnly<SoftwareCreateParams>, string>[] = [
    'software',
    'dxoneThinkletApp',
    'launcher',
    'mdmClient',
    'deviceOwner',
    'uploader',
    'sound',
    'sibyl',
  ];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}

export function isSoftwareUpdateParams(value: unknown): value is SoftwareUpdateParams {
  const sProps: Prop<SoftwareUpdateParams, string>[] = ['remarks'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}
