export const MIMI_ASR_API_URL = 'wss://service.mimi.fd.ai';
export const MIMI_ASR_MIME_TYPE = 'audio/x-pcm';
export const MIMI_ASR_PROCESS = 'nict-asr';
export const MIMI_ASR_LANGUAGE = 'ja';

export const NICT_ASR_FORMAT = 'v2';
export const NICT_ASR_PROGRESSIVE = false;
export const NICT_ASR_TEMPORARY = true;
export const NICT_ASR_TEMPORARY_INTERVAL = 1500;

export const BIT_DEPTH = 16;
export const SAMPLE_RATE = 44100;
export const AUDIO_CHANNELS = 1;

export type SpeechStatus = 'recog-in-progress' | 'recog-finished';

export type Speech = {
  sessionId: string;
  status?: SpeechStatus;
  text: string;
  lang: string;
  timestamp: number;
};
