import { Observable } from 'rxjs';

import { ShareCreateParams, SharedArchiveContent } from '../models/share.model';

export abstract class SharedArchiveUsecase {
  abstract get contents$(): Observable<SharedArchiveContent[]>;
  abstract get group$(): Observable<string | null>;

  abstract changeGroup(groupId: string | null): void;
  abstract reload(): void;

  abstract createShare(params: ShareCreateParams): Observable<never>;
  abstract deleteShare(shareKey: string, groupId: string): Observable<never>;
}
