import { QueryParams } from '@daikin-tic/dxone-com-lib';

export const CATEGORY_TYPES = ['type', 'model', 'code', 'content', 'part', 'condition', 'work'] as const;
export type CategoryType = (typeof CATEGORY_TYPES)[number];

export type PropertyQueryParams = QueryParams & { category: CategoryType };

export type Property = {
  id: string;
  name: string;
  type?: string;
};
