import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { ManualCreateParams, ManualStep, ManualStepsCreateParams, Manuals } from '../models/manual.model';

export abstract class ManualUsecase {
  abstract get manuals$(): Observable<Manuals>;

  abstract createManual(params: ManualCreateParams): Observable<string>;
  abstract deleteManual(manualId: string): Observable<never>;

  abstract createManualSteps(manualId: string, params: ManualStepsCreateParams): Observable<ListResponse<ManualStep>>;
}
