import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Thinklet, ThinkletCommand, ThinkletCommandQueryParams, ThinkletQueryParams, ThinkletUpdateParams } from '../models/thinklet.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';

@Injectable()
export class ThinkletService extends ThinkletGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>> {
    return this._http.get<ListResponse<Thinklet>>('api/rss/thinklets', { params });
  }

  updateThinklets(params: ThinkletUpdateParams): Observable<ListResponse<Thinklet>> {
    return this._http.put<ListResponse<Thinklet>>('api/rss/thinklets', params);
  }

  listThinkletCommands(imei: string, params: ThinkletCommandQueryParams): Observable<ListResponse<ThinkletCommand>> {
    return this._http.get<ListResponse<ThinkletCommand>>(`api/rss/thinklets/${imei}/commands`, { params });
  }
}
