import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Noise, NoiseQueryParams, NoiseTargets, NoiseUpdateParams } from '../models/noise.model';

export abstract class NoiseArchiveGateway {
  abstract listNoise(params: NoiseQueryParams): Observable<ListResponse<Noise>>;
  abstract getNoise(noiseId: string, target: NoiseTargets): Observable<{ url: string }>;
  abstract updateNoise(noiseId: string, params: NoiseUpdateParams): Observable<Noise>;
}
