import { Observable } from 'rxjs';

import {
  SupportTeam,
  SupportTeamCallees,
  SupportTeamCreateParams,
  SupportTeamQueryParams,
  SupportTeamUpdateParams,
  SupportTeamsUpdateParams,
} from '../models/support-team.model';
import { ListResponse } from '../models/utility.model';

export abstract class SupportTeamGateway {
  abstract listSupportTeams(params: SupportTeamQueryParams): Observable<ListResponse<SupportTeam>>;
  abstract getSupportTeamCallees(teamId: string): Observable<SupportTeamCallees>;
  abstract createSupportTeam(params: SupportTeamCreateParams): Observable<SupportTeam>;
  abstract updateSupportTeam(teamId: string, params: SupportTeamUpdateParams): Observable<SupportTeam>;
  abstract updateSupportTeams(params: SupportTeamsUpdateParams): Observable<ListResponse<SupportTeam>>;
  abstract deleteSupportTeam(teamId: string): Observable<void>;
}
