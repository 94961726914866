import { Observable } from 'rxjs';

import { Connection, ConnectionCreateParams } from '../models/connection.model';

export abstract class ConnectionGateway {
  abstract createConnection(channelId: string, params: ConnectionCreateParams): Observable<Connection>;
  abstract updateConnectionAudio(channelId: string, connectionId: string, audio: boolean): Observable<Connection>;
  abstract updateConnectionVideo(channelId: string, connectionId: string, video: boolean): Observable<Connection>;
  abstract deleteConnection(channelId: string, connectionId: string): Observable<void>;
}
