import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { AsyncSubject, Observable, partition } from 'rxjs';
import { delayWhen, filter, finalize, map, repeatWhen, takeUntil } from 'rxjs/operators';

import {
  AuthUsecase,
  DistinctSubject,
  NeverError,
  Period,
  PeriodSubject,
  UserTimezone,
  WebSocketSyncData,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import {
  ArchiveChannel,
  ArchiveCombineVideo,
  ArchiveCombineVideoCreateParams,
  ArchiveMeasurement,
  ArchiveVideo,
} from '../models/archive.model';
import { Channel, ChannelQueryParams, ChannelUpdateParams, Channels } from '../models/channel.model';
import { Message } from '../models/message.model';
import { Snapshot } from '../models/snapshot.model';
import { ArchiveGateway } from '../usecases/archive.gateway';
import { ChannelGateway } from '../usecases/channel.gateway';
import { MessageGateway } from '../usecases/message.gateway';
import { RemoteArchiveUsecase } from '../usecases/remote-archive.usecase';

@Injectable()
export class RemoteArchiveInteractor extends RemoteArchiveUsecase {
  get channels$(): Observable<ArchiveChannel[]> {
    return this._channels.pipe(
      map(channels =>
        channels
          .values()
          .filter(channel => channel.status === 'finish')
          .map(channel => ({
            ...channel,
            videos$: this.listArchiveVideos(channel.channelId),
            combineVideos$: this.listArchiveCombineVideos(channel.channelId),
            messages$: this.listMessages(channel.channelId),
            snapshots$: this.listSnapshots(channel.channelId),
            measurements$: this.listArchiveMeasurements(channel.channelId),
          })),
      ),
    );
  }
  get period$(): Observable<Period> {
    return this._period;
  }

  private readonly _channels = new DistinctSubject<Channels>(new Channels());
  private readonly _period = new PeriodSubject({ from: -1, to: -1 });

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _archiveGateway: ArchiveGateway,
    private _channelGateway: ChannelGateway,
    private _messageGateway: MessageGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._period.now();
    });

    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    this.period$
      .pipe(
        filter(({ from, to }) => from >= 0 && to >= 0),
        takeUntil(close$),
        finalize(() => this._channels.next(new Channels())),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => open$))),
      )
      .subscribe(({ from, to }) => {
        const queryParams: ChannelQueryParams = { createdFrom: from.toString(), createdTo: to.toString() };
        recursiveQuery(params => this._channelGateway.listChannels(params), queryParams).subscribe(channels =>
          this._channels.next(new Channels(channels)),
        );
      });

    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'channel'),
        map(({ data }) => data as WebSocketSyncData<Channel>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'delete':
            // nop
            break;
          case 'update':
            this.replaceChannel(data.payload);
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  changeTimezone(timezone: UserTimezone): void {
    this._period.tz(timezone);
  }

  changePeriod(period: Period): void {
    this._period.next(period);
  }

  reload(): void {
    const now = dayjs().tz();
    if (this._period.includes(now.startOf('day').unix())) {
      this._period.next({ from: this._period.value.from, to: now.unix() });
    }
  }

  updateChannel(channelId: string, params: ChannelUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._channelGateway.updateChannel(channelId, params).subscribe({
      next: updatedChannel => this.replaceChannel(updatedChannel),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteChannel(channelId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._channelGateway.deleteChannel(channelId).subscribe({
      next: () => this._channels.next(this._channels.value.delete(channelId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getArchiveVideo(channelId: string, connectionId: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._archiveGateway.getArchiveVideo(channelId, connectionId).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteArchiveVideo(channelId: string, connectionId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.deleteArchiveVideo(channelId, connectionId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createArchiveCombineVideo(channelId: string, params: ArchiveCombineVideoCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.createArchiveCombineVideo(channelId, params).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getArchiveCombineVideo(channelId: string, videoId: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._archiveGateway.getArchiveCombineVideo(channelId, videoId).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteArchiveCombineVideo(channelId: string, videoId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.deleteArchiveCombineVideo(channelId, videoId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteArchiveMessages(channelId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.deleteArchiveMessages(channelId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSnapshot(channelId: string, snapshotId: string, filename?: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._archiveGateway.getSnapshot(channelId, snapshotId, filename).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteSnapshot(channelId: string, snapshotId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.deleteSnapshot(channelId, snapshotId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getArchiveMeasurement(channelId: string, measurementId: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._archiveGateway.getArchiveMeasurement(channelId, measurementId).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteArchiveMeasurement(channelId: string, measurementId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.deleteArchiveMeasurement(channelId, measurementId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private listArchiveVideos(channelId: string): Observable<ArchiveVideo[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveVideos(channelId, params), {});
  }

  private listArchiveCombineVideos(channelId: string): Observable<ArchiveCombineVideo[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveCombineVideos(channelId, params), {});
  }

  private listMessages(channelId: string): Observable<Message[]> {
    return recursiveQuery(params => this._messageGateway.listMessages(channelId, params), {});
  }

  private listSnapshots(channelId: string): Observable<Snapshot[]> {
    return recursiveQuery(params => this._archiveGateway.listSnapshots(channelId, params), {});
  }

  private listArchiveMeasurements(channelId: string): Observable<ArchiveMeasurement[]> {
    return recursiveQuery(params => this._archiveGateway.listArchiveMeasurements(channelId, params), {});
  }

  private replaceChannel(channel: Channel): void {
    const channels = this._channels.value.values();
    const index = channels.findIndex(({ channelId, version }) => channelId === channel.channelId && version !== channel.version);
    if (index < 0) {
      return;
    }
    channels[index] = { ...channels[index], ...channel };
    this._channels.next(new Channels(channels));
  }
}
