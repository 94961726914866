import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Tag, TagCreateParams, TagQueryParams } from '../models/tag.model';

export abstract class TagGateway {
  abstract listTags(params: TagQueryParams): Observable<ListResponse<Tag>>;
  abstract createTag(params: TagCreateParams): Observable<Tag>;
  abstract deleteTag(tagId: string): Observable<void>;
}
