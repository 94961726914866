<router-outlet></router-outlet>
<div *ngIf="progress$ | async as progress" class="app-progress">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <span *ngIf="progress.total >= threshold" class="text-light">{{ ((progress.loaded / progress.total) * 100).toFixed() }}%</span>
  <ngb-progressbar *ngIf="progress.total >= threshold" type="primary" [max]="progress.total" [value]="progress.loaded"></ngb-progressbar>
</div>
<div class="app-toasts">
  <ngb-toast
    *ngFor="let toast of toasts$ | async"
    [class]="toast.type"
    [autohide]="true"
    [delay]="toast.delay"
    (hidden)="toast.hide()"
    data-test-output="toast"
  >
    {{ toast.message | translate }}
  </ngb-toast>
</div>
