import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';

import { AudioUsecase } from '../usecases/audio.usecase';

@Injectable()
export class AudioInteractor extends AudioUsecase {
  get context(): AudioContext {
    return Howler.ctx;
  }
  get ringtone(): { play: () => void; stop: () => void; playing: () => boolean } {
    return this._ringtone;
  }

  private _ringtone: Howl;

  constructor() {
    super();
    this._ringtone = new Howl({
      src: ['assets/sounds/ringtone.mp3'],
      loop: true,
      volume: 0.1,
    });
  }
}
