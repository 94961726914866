import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { CloudStorage } from '../models/cloud-storage.modal';
import {
  Reference,
  ReferenceCreateParams,
  ReferenceQueryParams,
  ReferenceUpdateParams,
  ReferenceVideo,
  ReferenceVideoCreateParams,
  ReferenceVideoQueryParams,
  ReferenceVideoUpdateParams,
} from '../models/reference.model';
import { ReferenceGateway } from '../usecases/reference.gateway';

@Injectable()
export class ReferenceService extends ReferenceGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listReferences(params: ReferenceQueryParams): Observable<ListResponse<Reference>> {
    return this._http.get<ListResponse<Reference>>('api/rss/references', { params });
  }

  createReference(params: ReferenceCreateParams): Observable<Reference> {
    return this._http.post<Reference>('api/rss/references', params);
  }

  updateReference(referenceId: string, params: ReferenceUpdateParams): Observable<Reference> {
    return this._http.put<Reference>(`api/rss/references/${referenceId}`, params);
  }

  deleteReference(referenceId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/references/${referenceId}`);
  }

  listReferenceVideos(referenceId: string, params: ReferenceVideoQueryParams): Observable<ListResponse<ReferenceVideo>> {
    return this._http.get<ListResponse<ReferenceVideo>>(`api/rss/references/${referenceId}/videos`, { params });
  }

  createReferenceVideo(referenceId: string, params: ReferenceVideoCreateParams): Observable<CloudStorage> {
    return this._http.post<CloudStorage>(`api/rss/references/${referenceId}/videos`, params);
  }

  getReferenceVideo(referenceId: string, videoId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/references/${referenceId}/videos/${videoId}`);
  }

  updateReferenceVideo(referenceId: string, videoId: string, params: ReferenceVideoUpdateParams): Observable<ReferenceVideo> {
    return this._http.put<ReferenceVideo>(`api/rss/references/${referenceId}/videos/${videoId}`, params);
  }

  deleteReferenceVideo(referenceId: string, videoId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/references/${referenceId}/videos/${videoId}`).pipe(ignoreElements());
  }
}
