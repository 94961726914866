import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Software, SoftwareCreateParams, SoftwareQueryParams, SoftwareType, SoftwareUpdateParams } from '../models/software.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { SoftwareGateway } from '../usecases/software.gateway';
import { SoftwareUsecase } from '../usecases/software.usecase';

@Injectable()
export class SoftwareInteractor extends SoftwareUsecase {
  get softwares$(): Observable<Software[]> {
    return this._softwares;
  }

  private readonly _softwares = new DistinctSubject<Software[]>([]);

  constructor(private _authUsecase: AuthUsecase, private _softwareGateway: SoftwareGateway) {
    super();
    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  createSoftware(type: SoftwareType, params: SoftwareCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._softwareGateway.createSoftware(type, params).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSoftware(type: SoftwareType, software: string, params: SoftwareUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._softwareGateway.updateSoftware(type, software, params).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteSoftware(type: SoftwareType, software: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._softwareGateway.deleteSoftware(type, software).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reload(): void {
    this.onSignIn();
  }

  private onSignIn(): void {
    recursiveQuery<SoftwareQueryParams, Software>(params => this._softwareGateway.listSoftware('thinklet', params), {}).subscribe(
      softwares => this._softwares.next(softwares),
    );
  }

  private onSignOut(): void {
    this._softwares.next([]);
  }
}
