import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Share, ShareCreateParams, ShareQueryParams, SharedContent } from '../models/share.model';

export abstract class ShareGateway {
  abstract listShares(params: ShareQueryParams): Observable<ListResponse<SharedContent>>;
  abstract createShare(params: ShareCreateParams): Observable<ListResponse<Share>>;
  abstract deleteShare(shareKey: string, groupId: string): Observable<void>;
}
