import { Observable } from 'rxjs';

import { Firmware, FirmwareCreateParams, FirmwareQueryParams, FirmwareType, FirmwareUpdateParams } from '../models/firmware.model';
import { ListResponse } from '../models/utility.model';

export abstract class FirmwareGateway {
  abstract listFirmwares(type: FirmwareType, params: FirmwareQueryParams): Observable<ListResponse<Firmware>>;
  abstract createFirmware(type: FirmwareType, params: FirmwareCreateParams): Observable<Firmware>;
  abstract updateFirmware(type: FirmwareType, firmware: string, params: FirmwareUpdateParams): Observable<Firmware>;
  abstract deleteFirmware(type: FirmwareType, firmware: string): Observable<void>;
}
