import { ImmutableMap, Prop, QueryParams, RequiredOnly, isNumber, isObject, isString } from '@daikin-tic/dxone-com-lib';

type ReferenceRequiredProps = {
  type: string;
  name: string;
};

type ReferenceOptionalProps = {
  tags?: string[];
};

type ReferenceProvidedProps = {
  referenceId: string;
  updatedAt: number;
  version: number;
};

export type ReferenceQueryParams = QueryParams;

export type ReferenceCreateParams = ReferenceRequiredProps & ReferenceOptionalProps;

export type ReferenceUpdateParams = Partial<ReferenceRequiredProps> & ReferenceOptionalProps;

export type Reference = ReferenceRequiredProps & ReferenceOptionalProps & ReferenceProvidedProps;

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isReferenceCreateParams(value: unknown): value is ReferenceCreateParams {
  const sProps: Prop<RequiredOnly<ReferenceCreateParams>, string>[] = ['type', 'name'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && isStringArray(value['tags']);
}

export function isReferenceUpdateParams(value: unknown): value is ReferenceUpdateParams {
  const sProps: Prop<ReferenceUpdateParams, string>[] = ['type', 'name'];
  return isObject(value) && (sProps.some(prop => isString(value[prop])) || isStringArray(value['tags']));
}

export class References extends ImmutableMap<string, Reference> {
  constructor(references?: Reference[] | Iterable<Reference>) {
    super(reference => reference.referenceId, references);
  }

  protected clone(references?: Reference[] | Iterable<Reference>): this {
    return new References(references) as this;
  }
}

export type ReferenceVideoChapter = {
  timestamp: number;
  title: string;
};

type ReferenceVideoRequiredProps = {
  name: string;
  videoFile: string;
  chapters: ReferenceVideoChapter[];
  models: string[];
  codes: string[];
  contents: string[];
  parts: string[];
  conditions: string[];
  works: string[];
};

type ReferenceVideoOptionalProps = {
  chapterFile?: string;
};

type ReferenceVideoProvidedProps = {
  referenceId: string;
  videoId: string;
};

export type ReferenceVideoQueryParams = QueryParams;

export type ReferenceVideoCreateParams = ReferenceVideoRequiredProps & ReferenceVideoOptionalProps;

export type ReferenceVideoUpdateParams = Partial<ReferenceVideoRequiredProps> & ReferenceVideoOptionalProps;

export type ReferenceVideo = ReferenceVideoRequiredProps & ReferenceVideoOptionalProps & ReferenceVideoProvidedProps;

export function isReferenceVideoChapter(value: unknown): value is ReferenceVideoChapter {
  const sProps: Prop<RequiredOnly<ReferenceVideoChapter>, string>[] = ['title'];
  const nProps: Prop<RequiredOnly<ReferenceVideoChapter>, number>[] = ['timestamp'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && nProps.every(prop => isNumber(value[prop]));
}

export function isReferenceVideoChapterArray(value: unknown): value is ReferenceVideoChapter[] {
  return Array.isArray(value) && value.every(isReferenceVideoChapter);
}

export function isReferenceVideoCreateParams(value: unknown): value is ReferenceVideoCreateParams {
  const sProps: Prop<RequiredOnly<ReferenceVideoCreateParams>, string>[] = ['name', 'videoFile'];
  const sArrProps: Prop<RequiredOnly<ReferenceVideoCreateParams>, string[]>[] = [
    'models',
    'codes',
    'contents',
    'parts',
    'conditions',
    'works',
  ];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    sArrProps.every(prop => isStringArray(value[prop])) &&
    isReferenceVideoChapterArray(value['chapters'])
  );
}

export function isReferenceVideoUpdateParams(value: unknown): value is ReferenceVideoUpdateParams {
  const sProps: Prop<ReferenceVideoUpdateParams, string>[] = ['name', 'chapterFile'];
  const sArrProps: Prop<RequiredOnly<ReferenceVideoCreateParams>, string[]>[] = [
    'models',
    'codes',
    'contents',
    'parts',
    'conditions',
    'works',
  ];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) ||
      sArrProps.some(prop => isStringArray(value[prop])) ||
      isReferenceVideoChapterArray(value['chapters']))
  );
}
