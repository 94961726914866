abstract class AbstractError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NeverError extends AbstractError {
  constructor(value: never) {
    super(`Unexpected value '${value}'. Should have been never.`);
  }
}

export class AuthError extends AbstractError {
  constructor(public code: string) {
    super();
  }
}

export type MediaDeviceErrorReason = 'permit' | 'device' | 'camera' | 'mic';
export class MediaDeviceError extends AbstractError {
  constructor(public reason: MediaDeviceErrorReason) {
    super();
  }
}
