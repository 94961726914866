import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

import { MgmtDept, MgmtDeptRestriction, isMgmtDept } from '../models/mgmt-dept.model';
import { DistinctSubject } from '../models/utility.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { MgmtDeptGateway } from '../usecases/mgmt-dept.gateway';
import { MgmtDeptUsecase } from '../usecases/mgmt-dept.usecase';

@Injectable()
export class MgmtDeptInteractor extends MgmtDeptUsecase {
  get mgmtDept$(): Observable<MgmtDept> {
    return this._mgmtDept.pipe(filter(isMgmtDept));
  }

  private readonly _mgmtDept = new DistinctSubject<MgmtDept | undefined>(undefined);

  constructor(private _authUsecase: AuthUsecase, private _mgmtDeptGateway: MgmtDeptGateway) {
    super();
    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  updateMgmtDeptRestriction(restriction: MgmtDeptRestriction): Observable<never> {
    const result = new AsyncSubject<never>();
    const mgmtDeptId = this._mgmtDept.value?.mgmtDeptId || '';
    this._mgmtDeptGateway.updateMgmtDeptRestriction(mgmtDeptId, restriction).subscribe({
      next: mgmtDept => this._mgmtDept.next(mgmtDept),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  private onSignIn(): void {
    this._authUsecase.payload$
      .pipe(mergeMap(({ mgmtDeptId }) => this._mgmtDeptGateway.getMgmtDept(mgmtDeptId || '')))
      .subscribe(mgmtDept => this._mgmtDept.next(mgmtDept));
  }

  private onSignOut(): void {
    this._mgmtDept.next(undefined);
  }
}
