import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import {
  Manual,
  ManualCreateParams,
  ManualQueryParams,
  ManualStep,
  ManualStepQueryParams,
  ManualStepsCreateParams,
} from '../models/manual.model';

export abstract class ManualGateway {
  abstract listManuals(params: ManualQueryParams): Observable<ListResponse<Manual>>;
  abstract createManual(params: ManualCreateParams): Observable<Manual>;
  abstract deleteManual(manualId: string): Observable<void>;

  abstract listManualStep(manualId: string, params: ManualStepQueryParams): Observable<ListResponse<ManualStep>>;
  abstract createManualSteps(manualId: string, params: ManualStepsCreateParams): Observable<ListResponse<ManualStep>>;
}
