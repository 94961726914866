import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { AsyncSubject, Observable, partition } from 'rxjs';
import { delayWhen, filter, finalize, repeatWhen, takeUntil } from 'rxjs/operators';

import {
  AuthUsecase,
  DistinctSubject,
  Period,
  PeriodSubject,
  UserTimezone,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import { Noise, NoiseQueryParams, NoiseTargets, NoiseUpdateParams } from '../models/noise.model';
import { NoiseArchiveGateway } from '../usecases/noise-archive.gateway';
import { NoiseArchiveUsecase } from '../usecases/noise-archive.usecase';

@Injectable()
export class NoiseArchiveInteractor extends NoiseArchiveUsecase {
  get noises$(): Observable<Noise[]> {
    return this._noises;
  }
  get period$(): Observable<Period> {
    return this._period;
  }

  private readonly _noises = new DistinctSubject<Noise[]>([]);
  private readonly _period = new PeriodSubject({ from: -1, to: -1 });

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _noiseArchiveGateway: NoiseArchiveGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._period.now();
    });
    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    this.period$
      .pipe(
        filter(({ from, to }) => from >= 0 && to >= 0),
        takeUntil(close$),
        finalize(() => this._noises.next([])),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => open$))),
      )
      .subscribe(({ from, to }) => {
        const queryParams: NoiseQueryParams = { createdFrom: from.toString(), createdTo: to.toString() };
        recursiveQuery(params => this._noiseArchiveGateway.listNoise(params), queryParams).subscribe(noises => this._noises.next(noises));
      });
  }

  changeTimezone(timezone: UserTimezone): void {
    this._period.tz(timezone);
  }

  changePeriod(period: Period): void {
    this._period.next(period);
  }

  reload(): void {
    const now = dayjs().tz();
    if (this._period.includes(now.startOf('day').unix())) {
      this._period.next({ from: this._period.value.from, to: now.unix() });
    }
  }

  getNoise(noiseId: string, target: NoiseTargets): Observable<string> {
    const result = new AsyncSubject<string>();
    this._noiseArchiveGateway.getNoise(noiseId, target).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateNoise(noiseId: string, params: NoiseUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._noiseArchiveGateway.updateNoise(noiseId, params).subscribe({
      next: noise => this.replaceNoise(noise),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private replaceNoise(noise: Noise): void {
    const noises = this._noises.value;
    const index = noises.findIndex(({ noiseId }) => noiseId === noise.noiseId);
    if (index < 0) {
      return;
    }
    noises[index] = noise;
    this._noises.next([...noises]);
  }
}
