import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import {
  ArchiveCombineVideo,
  ArchiveCombineVideoCreateParams,
  ArchiveCombineVideoQueryParams,
  ArchiveMeasurement,
  ArchiveMeasurementFile,
  ArchiveMeasurementFileQueryParams,
  ArchiveMeasurementFileUpdateParams,
  ArchiveMeasurementQueryParams,
  ArchiveVideo,
  ArchiveVideoQueryParams,
} from '../models/archive.model';
import { Snapshot, SnapshotQueryParams } from '../models/snapshot.model';

export abstract class ArchiveGateway {
  abstract listArchiveVideos(channelId: string, params: ArchiveVideoQueryParams): Observable<ListResponse<ArchiveVideo>>;
  abstract getArchiveVideo(channelId: string, connectionId: string): Observable<{ url: string }>;
  abstract deleteArchiveVideo(channelId: string, connectionId: string): Observable<void>;

  abstract listArchiveCombineVideos(
    channelId: string,
    params: ArchiveCombineVideoQueryParams,
  ): Observable<ListResponse<ArchiveCombineVideo>>;
  abstract createArchiveCombineVideo(channelId: string, params: ArchiveCombineVideoCreateParams): Observable<ArchiveCombineVideo>;
  abstract getArchiveCombineVideo(channelId: string, videoId: string): Observable<{ url: string }>;
  abstract deleteArchiveCombineVideo(channelId: string, videoId: string): Observable<void>;
  abstract deleteArchiveMessages(channelId: string): Observable<void>;

  abstract listSnapshots(channelId: string, params: SnapshotQueryParams): Observable<ListResponse<Snapshot>>;
  abstract getSnapshot(channelId: string, snapshotId: string, filename?: string): Observable<{ url: string }>;
  abstract deleteSnapshot(channelId: string, snapshotId: string): Observable<void>;

  abstract listArchiveMeasurements(channelId: string, params: ArchiveMeasurementQueryParams): Observable<ListResponse<ArchiveMeasurement>>;
  abstract getArchiveMeasurement(channelId: string, measurementId: string): Observable<{ url: string }>;
  abstract deleteArchiveMeasurement(channelId: string, measurementId: string): Observable<void>;

  abstract listArchiveMeasurementFiles(
    channelId: string,
    params: ArchiveMeasurementFileQueryParams,
  ): Observable<ListResponse<ArchiveMeasurementFile>>;
  abstract updateArchiveMeasurementFile(
    channelId: string,
    measurementId: string,
    params: ArchiveMeasurementFileUpdateParams,
  ): Observable<ArchiveMeasurementFile>;
}
