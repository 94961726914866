import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

import { Work } from './work.model';

type ReportRequiredProps = {
  name: string;
  site: string;
  scheduledAt: number;
};

type ReportOptionalProps = {
  remarks?: string;
};

type ReportProvidedProps = {
  reportId: string;
  works?: Work[];
  createdAt: number;
  updatedAt: number;
  version: number;
  author: string;
  assignedUsers: string[];
  groupId: string;
};

export type ReportQueryParams = QueryParams & { scheduledFrom: string; scheduledTo: string };

export type Report = ReportRequiredProps & ReportOptionalProps & ReportProvidedProps;

export class Reports extends ImmutableMap<string, Report> {
  constructor(reports?: Report[] | Iterable<Report>) {
    super(report => report.reportId, reports);
  }

  protected clone(reports?: Report[] | Iterable<Report>): this {
    return new Reports(reports) as this;
  }
}
