import { Prop, QueryParams, RequiredOnly, isObject, isString } from './utility.model';

export const FIRMWARE_TYPES = ['thinklet'] as const;
export type FirmwareType = (typeof FIRMWARE_TYPES)[number];

type FirmwareRequiredProps = {
  firmware: string;
  softwares: string[];
};

type FirmwareOptionalProps = object;

type FirmwareProvidedProps = {
  type: FirmwareType;
  updatedAt: number;
};

export type FirmwareQueryParams = QueryParams;

export type FirmwareCreateParams = FirmwareRequiredProps & FirmwareOptionalProps;

export type FirmwareUpdateParams = Pick<FirmwareRequiredProps, 'softwares'> & FirmwareOptionalProps;

export type Firmware = FirmwareRequiredProps & FirmwareOptionalProps & FirmwareProvidedProps;

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isFirmwareCreateParams(value: unknown): value is FirmwareCreateParams {
  const sProps: Prop<RequiredOnly<FirmwareCreateParams>, string>[] = ['firmware'];
  return isObject(value) && (sProps.every(prop => isString(value[prop])) || isStringArray(value['softwares']));
}

export function isFirmwareUpdateParams(value: unknown): value is FirmwareUpdateParams {
  return isObject(value) && isStringArray(value['softwares']);
}
