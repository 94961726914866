import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { LibModule, httpInterceptorProviders } from '@daikin-tic/dxone-com-lib';

import { AccessPointInteractor } from './interactors/access-point.interactor';
import { AudioInteractor } from './interactors/audio.interactor';
import { InspectionResultArchiveInteractor } from './interactors/inspection-result-archive.interactor';
import { LocalArchiveInteractor } from './interactors/local-archive.interactor';
import { ManualInteractor } from './interactors/manual.interactor';
import { MeasurementFileArchiveInteractor } from './interactors/measurement-file-archive.interactor';
import { NoiseArchiveInteractor } from './interactors/noise-archive.interactor';
import { ReferenceInteractor } from './interactors/reference.interactor';
import { RemoteArchiveInteractor } from './interactors/remote-archive.interactor';
import { RemoteSupportInteractor } from './interactors/remote-support.interactor';
import { ReportInteractor } from './interactors/report.interactor';
import { SharedArchiveInteractor } from './interactors/shared-archive.interactor';
import { StepInteractor } from './interactors/step.interactor';
import { TagInteractor } from './interactors/tag.interactor';
import { ThinkletInteractor } from './interactors/thinklet.interactor';
import { TranscribeInteractor } from './interactors/transcribe.interactor';
import { WifiNetworkInteractor } from './interactors/wifi-network.interactor';
import { AccessPointService } from './services/access-point.service';
import { ArchiveService } from './services/archive.service';
import { ChannelService } from './services/channel.service';
import { CloudStorageService } from './services/cloud-storage.service';
import { ConnectionService } from './services/connection.service';
import { DateParserFormatterService } from './services/date-parser-formatter.service';
import { DatepickerI18nService } from './services/datepicker-i18n.service';
import { InspectionResultService } from './services/inspection-result.service';
import { ManualService } from './services/manual.service';
import { MessageService } from './services/message.service';
import { NoiseService } from './services/noise.service';
import { OfferService } from './services/offer.service';
import { PropertyService } from './services/property.service';
import { RecordService } from './services/record.service';
import { ReferenceService } from './services/reference.service';
import { ReportService } from './services/report.service';
import { ShareService } from './services/share.service';
import { SnapshotService } from './services/snapshot.service';
import { StepService } from './services/step.service';
import { TagService } from './services/tag.service';
import { ThinkletService } from './services/thinklet.service';
import { WifiNetworkService } from './services/wifi-network.service';
import { AccessPointGateway } from './usecases/access-point.gateway';
import { AccessPointUsecase } from './usecases/access-point.usecase';
import { ArchiveGateway } from './usecases/archive.gateway';
import { AudioUsecase } from './usecases/audio.usecase';
import { ChannelGateway } from './usecases/channel.gateway';
import { CloudStorageGateway } from './usecases/cloud-storage.gateway';
import { ConnectionGateway } from './usecases/connection.gateway';
import { InspectionResultArchiveGateway } from './usecases/inspection-result-archive.gateway';
import { InspectionResultArchiveUsecase } from './usecases/inspection-result-archive.usecase';
import { LocalArchiveUsecase } from './usecases/local-archive.usecase';
import { ManualGateway } from './usecases/manual.gateway';
import { ManualUsecase } from './usecases/manual.usecase';
import { MeasurementFileArchiveUsecase } from './usecases/measurement-file-archive.usecase';
import { MessageGateway } from './usecases/message.gateway';
import { NoiseArchiveGateway } from './usecases/noise-archive.gateway';
import { NoiseArchiveUsecase } from './usecases/noise-archive.usecase';
import { OfferGateway } from './usecases/offer.gateway';
import { PropertyGateway } from './usecases/property.gateway';
import { RecordGateway } from './usecases/record.gateway';
import { ReferenceGateway } from './usecases/reference.gateway';
import { ReferenceUsecase } from './usecases/reference.usecase';
import { RemoteArchiveUsecase } from './usecases/remote-archive.usecase';
import { RemoteSupportUsecase } from './usecases/remote-support.usecase';
import { ReportGateway } from './usecases/report.gateway';
import { ReportUsecase } from './usecases/report.usecase';
import { ShareGateway } from './usecases/share.gateway';
import { SharedArchiveUsecase } from './usecases/shared-archive.usecase';
import { SnapshotGateway } from './usecases/snapshot.gateway';
import { StepGateway } from './usecases/step.gateway';
import { StepUsecase } from './usecases/step.usecase';
import { TagGateway } from './usecases/tag.gateway';
import { TagUsecase } from './usecases/tag.usecase';
import { ThinkletGateway } from './usecases/thinklet.gateway';
import { ThinkletUsecase } from './usecases/thinklet.usecase';
import { TranscribeUsecase } from './usecases/transcribe.usecase';
import { WifiNetworkGateway } from './usecases/wifi-network.gateway';
import { WifiNetworkUsecase } from './usecases/wifi-network.usecase';

@NgModule({
  providers: [
    httpInterceptorProviders,
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterService },
    { provide: NgbDatepickerI18n, useClass: DatepickerI18nService },
    { provide: AccessPointUsecase, useClass: AccessPointInteractor },
    { provide: AudioUsecase, useClass: AudioInteractor },
    { provide: InspectionResultArchiveUsecase, useClass: InspectionResultArchiveInteractor },
    { provide: LocalArchiveUsecase, useClass: LocalArchiveInteractor },
    { provide: ManualUsecase, useClass: ManualInteractor },
    { provide: MeasurementFileArchiveUsecase, useClass: MeasurementFileArchiveInteractor },
    { provide: NoiseArchiveUsecase, useClass: NoiseArchiveInteractor },
    { provide: ReferenceUsecase, useClass: ReferenceInteractor },
    { provide: RemoteArchiveUsecase, useClass: RemoteArchiveInteractor },
    { provide: RemoteSupportUsecase, useClass: RemoteSupportInteractor },
    { provide: ReportUsecase, useClass: ReportInteractor },
    { provide: SharedArchiveUsecase, useClass: SharedArchiveInteractor },
    { provide: StepUsecase, useClass: StepInteractor },
    { provide: TagUsecase, useClass: TagInteractor },
    { provide: ThinkletUsecase, useClass: ThinkletInteractor },
    { provide: TranscribeUsecase, useClass: TranscribeInteractor },
    { provide: WifiNetworkUsecase, useClass: WifiNetworkInteractor },
    { provide: AccessPointGateway, useClass: AccessPointService },
    { provide: ArchiveGateway, useClass: ArchiveService },
    { provide: ChannelGateway, useClass: ChannelService },
    { provide: CloudStorageGateway, useClass: CloudStorageService },
    { provide: ConnectionGateway, useClass: ConnectionService },
    { provide: InspectionResultArchiveGateway, useClass: InspectionResultService },
    { provide: ManualGateway, useClass: ManualService },
    { provide: MessageGateway, useClass: MessageService },
    { provide: NoiseArchiveGateway, useClass: NoiseService },
    { provide: OfferGateway, useClass: OfferService },
    { provide: PropertyGateway, useClass: PropertyService },
    { provide: RecordGateway, useClass: RecordService },
    { provide: ReferenceGateway, useClass: ReferenceService },
    { provide: ReportGateway, useClass: ReportService },
    { provide: ShareGateway, useClass: ShareService },
    { provide: SnapshotGateway, useClass: SnapshotService },
    { provide: StepGateway, useClass: StepService },
    { provide: TagGateway, useClass: TagService },
    { provide: ThinkletGateway, useClass: ThinkletService },
    { provide: WifiNetworkGateway, useClass: WifiNetworkService },
  ],
  imports: [CommonModule, LibModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only.');
    }
  }
}
