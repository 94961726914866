import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DistinctSubject, NeverError, WebSocketSyncData, WebSocketUsecase, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { AccessPoint, AccessPointCreateParams, AccessPoints } from '../models/access-point.model';
import { AccessPointGateway } from '../usecases/access-point.gateway';
import { AccessPointUsecase } from '../usecases/access-point.usecase';

@Injectable()
export class AccessPointInteractor extends AccessPointUsecase {
  get accessPoints$(): Observable<AccessPoints> {
    return this._accessPoints;
  }

  private readonly _accessPoints = new DistinctSubject<AccessPoints>(new AccessPoints());

  constructor(private _webSocketUsecase: WebSocketUsecase, private _accessPointGateway: AccessPointGateway) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'access-point'),
        map(({ data }) => data as WebSocketSyncData<AccessPoint>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._accessPoints.next(this._accessPoints.value.set(data.payload));
            break;
          case 'delete':
            this._accessPoints.next(this._accessPoints.value.delete(data.payload.accessPointId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createAccessPoint(params: AccessPointCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._accessPointGateway.createAccessPoint(params).subscribe({
      next: createdAccessPoint => this._accessPoints.next(this._accessPoints.value.set(createdAccessPoint)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteAccessPoint(accessPointId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._accessPointGateway.deleteAccessPoint(accessPointId).subscribe({
      next: () => this._accessPoints.next(this._accessPoints.value.delete(accessPointId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._accessPointGateway.listAccessPoints(params), {}).subscribe(accessPoints => {
      this._accessPoints.next(new AccessPoints(accessPoints));
    });
  }

  private onSignOut(): void {
    this._accessPoints.next(new AccessPoints());
  }
}
