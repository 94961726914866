import { Observable } from 'rxjs';

import { SupportTeamCreateParams, SupportTeamUpdateParams, SupportTeams, SupportTeamsUpdateParams } from '../models/support-team.model';

export abstract class SupportTeamUsecase {
  abstract get supportTeams$(): Observable<SupportTeams>;

  abstract createSupportTeam(params: SupportTeamCreateParams): Observable<never>;
  abstract updateSupportTeam(teamId: string, params: SupportTeamUpdateParams): Observable<never>;
  abstract updateSupportTeams(params: SupportTeamsUpdateParams): Observable<never>;
  abstract deleteSupportTeam(teamId: string): Observable<never>;
}
