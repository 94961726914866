import { ImmutableMap, Prop, QueryParams, RequiredOnly, isObject, isString } from '@daikin-tic/dxone-com-lib';

export const WIFI_NETWORK_SECURITIES = ['WEP', 'WPA2-PSK'] as const;
export type WifiNetworkSecurity = (typeof WIFI_NETWORK_SECURITIES)[number];

type WifiNetworkRequiredProps = {
  ssid: string;
};

type WifiNetworkOptionalProps = {
  security?: WifiNetworkSecurity;
  password?: string;
};

type WifiNetworkProvidedProps = {
  wifiNetworkId: string;
  organizationId: string;
  updatedAt: number;
  version: number;
};

export type WifiNetworkQueryParams = QueryParams & { organizationId?: string };

export type WifiNetworkCreateParams = WifiNetworkRequiredProps & WifiNetworkOptionalProps & { organizationId?: string };

export type WifiNetwork = WifiNetworkRequiredProps & WifiNetworkOptionalProps & WifiNetworkProvidedProps;

export function isWifiNetworkSecurity(value: unknown): value is WifiNetworkSecurity {
  return WIFI_NETWORK_SECURITIES.some(v => v === value);
}

export function isWifiNetworkCreateParams(value: unknown): value is WifiNetworkCreateParams {
  const sProps: Prop<RequiredOnly<WifiNetworkCreateParams>, string>[] = ['ssid'];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    ((value['security'] == null && value['password'] == null) ||
      (WIFI_NETWORK_SECURITIES.some(v => v === value['security']) && isString(value['password'])))
  );
}

export class WifiNetworks extends ImmutableMap<string, WifiNetwork> {
  constructor(wifiNetworks?: WifiNetwork[] | Iterable<WifiNetwork>) {
    super(wifiNetwork => wifiNetwork.wifiNetworkId, wifiNetworks);
  }

  protected clone(wifiNetworks?: WifiNetwork[] | Iterable<WifiNetwork>): this {
    return new WifiNetworks(wifiNetworks) as this;
  }
}
