import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Noise, NoiseQueryParams, NoiseTargets, NoiseUpdateParams } from '../models/noise.model';
import { NoiseArchiveGateway } from '../usecases/noise-archive.gateway';

@Injectable()
export class NoiseService extends NoiseArchiveGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listNoise(params: NoiseQueryParams): Observable<ListResponse<Noise>> {
    return this._http.get<ListResponse<Noise>>('api/rss/noises', { params });
  }

  getNoise(noiseId: string, target: NoiseTargets): Observable<{ url: string }> {
    const params = { noiseId, target };
    return this._http.get<{ url: string }>(`api/rss/noises/${noiseId}`, { params });
  }

  updateNoise(noiseId: string, params: NoiseUpdateParams): Observable<Noise> {
    return this._http.put<Noise>(`api/rss/noises/${noiseId}`, params);
  }
}
