import { NgModule } from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { AuthInteractor } from './core/interactors/auth.interactor';
import { DeviceInteractor } from './core/interactors/device.interactor';
import { FirmwareInteractor } from './core/interactors/firmware.interactor';
import { GroupInteractor } from './core/interactors/group.interactor';
import { LocationInteractor } from './core/interactors/location.interactor';
import { MgmtDeptInteractor } from './core/interactors/mgmt-dept.interactor';
import { OrganizationInteractor } from './core/interactors/organization.interactor';
import { OrganizerInteractor } from './core/interactors/organizer.interactor';
import { ProgressInteractor } from './core/interactors/progress.interactor';
import { SoftwareInteractor } from './core/interactors/software.interactor';
import { SupportTeamInteractor } from './core/interactors/support-team.interactor';
import { ToastInteractor } from './core/interactors/toast.interactor';
import { UserInteractor } from './core/interactors/user.interactor';
import { WebSocketInteractor } from './core/interactors/web-socket.interactor';
import { AuthService } from './core/services/auth.service';
import { DeviceService } from './core/services/device.service';
import { FirmwareService } from './core/services/firmware.service';
import { GroupServie } from './core/services/group.service';
import { LocationService } from './core/services/location.service';
import { MgmtDeptService } from './core/services/mgmt-dept.service';
import { OrganizationService } from './core/services/organization.service';
import { OrganizerService } from './core/services/organizer.service';
import { SoftwareService } from './core/services/software.service';
import { SupportTeamService } from './core/services/support-team.service';
import { UserService } from './core/services/user.service';
import { AuthGateway } from './core/usecases/auth.gateway';
import { AuthUsecase } from './core/usecases/auth.usecase';
import { DeviceGateway } from './core/usecases/device.gateway';
import { DeviceUsecase } from './core/usecases/device.usecase';
import { FirmwareGateway } from './core/usecases/firmware.gateway';
import { FirmwareUsecase } from './core/usecases/firmware.usecase';
import { GroupGateway } from './core/usecases/group.gateway';
import { GroupUsecase } from './core/usecases/group.usecsae';
import { LocationGateway } from './core/usecases/location.gateway';
import { LocationUsecase } from './core/usecases/location.usecase';
import { MgmtDeptGateway } from './core/usecases/mgmt-dept.gateway';
import { MgmtDeptUsecase } from './core/usecases/mgmt-dept.usecase';
import { OrganizationGateway } from './core/usecases/organization.gateway';
import { OrganizationUsecase } from './core/usecases/organization.usecase';
import { OrganizerGateway } from './core/usecases/organizer.gateway';
import { OrganizerUsecase } from './core/usecases/organizer.usecase';
import { ProgressUsecase } from './core/usecases/progress.usecase';
import { SoftwareGateway } from './core/usecases/software.gateway';
import { SoftwareUsecase } from './core/usecases/software.usecase';
import { SupportTeamGateway } from './core/usecases/support-team.gateway';
import { SupportTeamUsecase } from './core/usecases/support-team.usecase';
import { ToastUsecase } from './core/usecases/toast.usecase';
import { UserGateway } from './core/usecases/user.gateway';
import { UserUsecase } from './core/usecases/user.usecase';
import { WebSocketUsecase } from './core/usecases/web-socket.usecase';

dayjs.extend(timezone);

@NgModule({
  providers: [
    { provide: AuthUsecase, useClass: AuthInteractor },
    { provide: DeviceUsecase, useClass: DeviceInteractor },
    { provide: FirmwareUsecase, useClass: FirmwareInteractor },
    { provide: GroupUsecase, useClass: GroupInteractor },
    { provide: LocationUsecase, useClass: LocationInteractor },
    { provide: MgmtDeptUsecase, useClass: MgmtDeptInteractor },
    { provide: OrganizationUsecase, useClass: OrganizationInteractor },
    { provide: OrganizerUsecase, useClass: OrganizerInteractor },
    { provide: ProgressUsecase, useClass: ProgressInteractor },
    { provide: SoftwareUsecase, useClass: SoftwareInteractor },
    { provide: SupportTeamUsecase, useClass: SupportTeamInteractor },
    { provide: ToastUsecase, useClass: ToastInteractor },
    { provide: UserUsecase, useClass: UserInteractor },
    { provide: WebSocketUsecase, useClass: WebSocketInteractor },
    { provide: AuthGateway, useClass: AuthService },
    { provide: DeviceGateway, useClass: DeviceService },
    { provide: FirmwareGateway, useClass: FirmwareService },
    { provide: GroupGateway, useClass: GroupServie },
    { provide: LocationGateway, useClass: LocationService },
    { provide: MgmtDeptGateway, useClass: MgmtDeptService },
    { provide: OrganizationGateway, useClass: OrganizationService },
    { provide: OrganizerGateway, useClass: OrganizerService },
    { provide: SoftwareGateway, useClass: SoftwareService },
    { provide: SupportTeamGateway, useClass: SupportTeamService },
    { provide: UserGateway, useClass: UserService },
  ],
})
export class LibModule {}
