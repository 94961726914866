import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { hide } from '@popperjs/core';
import { Observable } from 'rxjs';

import { AuthUsecase, Progress, ProgressUsecase, Toast, ToastUsecase } from '@daikin-tic/dxone-com-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  progress$?: Observable<Progress | null>;
  toasts$?: Observable<Toast[]>;

  readonly threshold = 1024 * 1024 * 100; // 100MB.

  constructor(
    private _router: Router,
    private _dropdownConfig: NgbDropdownConfig,
    private _modal: NgbModal,
    private _modalConfig: NgbModalConfig,
    private _authUsecase: AuthUsecase,
    private _progressUsecase: ProgressUsecase,
    private _toastUsecase: ToastUsecase,
  ) {
    this._dropdownConfig.popperOptions = ({ modifiers = [], ...surplus }) => ({ ...surplus, modifiers: [...modifiers, hide] });
    this._modalConfig.backdrop = 'static';
    this._modalConfig.keyboard = false;
    this._modalConfig.scrollable = true;
  }

  ngOnInit(): void {
    this._authUsecase.authState$.subscribe(({ status }) => {
      if (status !== 'signedIn' && !location.pathname.startsWith('/auth')) {
        this._modal.dismissAll();
        this._router.navigate(['/auth']);
      }
    });

    this.progress$ = this._progressUsecase.progress$;
    this.toasts$ = this._toastUsecase.toasts$;
  }
}
