import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { ListVideoQueryParams, Step, StepQueryParams, VoiceQueryParams } from '../models/step.model';

export abstract class StepGateway {
  abstract listSteps(params: StepQueryParams): Observable<ListResponse<Step>>;
  abstract listVideos(params: ListVideoQueryParams): Observable<ListResponse<string>>;
  abstract getVoice(params: VoiceQueryParams): Observable<{ url: string }>;
}
