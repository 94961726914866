import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Channel, ChannelCreateParams, ChannelQueryParams, ChannelUpdateParams } from '../models/channel.model';

export abstract class ChannelGateway {
  abstract listChannels(params: ChannelQueryParams): Observable<ListResponse<Channel>>;
  abstract createChannel(params: ChannelCreateParams): Observable<Channel>;
  abstract updateChannel(channelId: string, params: ChannelUpdateParams): Observable<Channel>;
  abstract deleteChannel(channelId: string): Observable<void>;
}
