import { Observable } from 'rxjs';

import { ListVideoQueryParams, StepQueryParams, Steps, VoiceQueryParams } from '../models/step.model';

export abstract class StepUsecase {
  abstract get steps$(): Observable<Steps>;

  abstract listSteps(params: StepQueryParams): Observable<never>;
  abstract listVideos(params: ListVideoQueryParams): Observable<string[]>;
  abstract getVoice(params: VoiceQueryParams): Observable<string>;

  abstract clearSteps(): void;
}
