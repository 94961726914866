import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Message, MessageCreateParams, MessageQueryParams, MessageUpdateParams } from '../models/message.model';

export abstract class MessageGateway {
  abstract listMessages(channelId: string, params: MessageQueryParams): Observable<ListResponse<Message>>;
  abstract createMessage(channelId: string, params: MessageCreateParams): Observable<Message>;
  abstract updateMessage(channelId: string, messageId: string, params: MessageUpdateParams): Observable<Message>;
}
