import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Firmware, FirmwareCreateParams, FirmwareQueryParams, FirmwareType, FirmwareUpdateParams } from '../models/firmware.model';
import { ListResponse } from '../models/utility.model';
import { FirmwareGateway } from '../usecases/firmware.gateway';

@Injectable()
export class FirmwareService extends FirmwareGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listFirmwares(type: FirmwareType, params: FirmwareQueryParams): Observable<ListResponse<Firmware>> {
    return this._http.get<ListResponse<Firmware>>(`api/com/device-registers/${type}`, { params });
  }

  createFirmware(type: FirmwareType, params: FirmwareCreateParams): Observable<Firmware> {
    return this._http.post<Firmware>(`api/com/device-registers/${type}`, params);
  }

  updateFirmware(type: FirmwareType, firmware: string, params: FirmwareUpdateParams): Observable<Firmware> {
    return this._http.put<Firmware>(`api/com/device-registers/${type}/firmware/${firmware}`, params);
  }

  deleteFirmware(type: FirmwareType, firmware: string): Observable<void> {
    return this._http.delete<void>(`api/com/device-registers/${type}/firmware/${firmware}`);
  }
}
