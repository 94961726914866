import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { InspectionResult, InspectionResultQueryParams, InspectionResultUpdateParams } from '../models/inspection-result.model';
import { InspectionResultArchiveGateway } from '../usecases/inspection-result-archive.gateway';

@Injectable()
export class InspectionResultService extends InspectionResultArchiveGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listInspectionResults(params: InspectionResultQueryParams): Observable<ListResponse<InspectionResult>> {
    return this._http.get<ListResponse<InspectionResult>>('api/rss/inspections', { params });
  }

  getInspectionResult(inspectionId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/inspections/${inspectionId}`);
  }

  updateInspectionResult(inspectionId: string, params: InspectionResultUpdateParams): Observable<InspectionResult> {
    return this._http.put<InspectionResult>(`api/rss/inspections/${inspectionId}`, params);
  }
}
