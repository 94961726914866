import { Observable } from 'rxjs';

import { GroupCreateParams, GroupUpdateParams, Groups } from '../models/group.model';

export abstract class GroupUsecase {
  abstract get groups$(): Observable<Groups>;

  abstract createGroup(params: GroupCreateParams): Observable<never>;
  abstract updateGroup(groupId: string, params: GroupUpdateParams): Observable<never>;
  abstract deleteGroup(groupId: string): Observable<never>;
}
