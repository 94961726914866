import { Observable } from 'rxjs';

import { WifiNetworkCreateParams, WifiNetworks } from '../models/wifi-network.model';

export abstract class WifiNetworkUsecase {
  abstract get wifiNetworks$(): Observable<WifiNetworks>;

  abstract createWifiNetwork(params: WifiNetworkCreateParams): Observable<never>;
  abstract deleteWifiNetwork(ssid: string): Observable<never>;
}
