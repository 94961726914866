import { ImmutableMap, Prop, QueryParams, RequiredOnly, isBoolean, isNumber, isObject, isString } from '@daikin-tic/dxone-com-lib';

import { Connection } from './connection.model';

export const CHANNEL_CAPACITY = 10;

export type ChannelStatus = 'standby' | 'active' | 'finish' | 'deleted' | 'error';

type ChannelArchive = {
  combine: boolean;
  snapshot: boolean;
  message: boolean;
  analysis: boolean;
  measurement: boolean;
};

type ChannelRequiredProps = {
  name: string;
};

type ChannelOptionalProps = {
  spotlight?: boolean;
  ctrlNo?: string;
  model?: string;
  code?: string;
  content?: string;
  remarks?: string;
};

type ChannelProvidedProps = {
  channelId: string;
  groupId: string;
  serviceId?: string;
  endpointUrl: string;
  status: ChannelStatus;
  transcribe: boolean;
  measurement: boolean;
  statInterval: number;
  sensorInterval: number;
  referenceCandidate: boolean;
  createdAt: number;
  updatedAt: number;
  version: number;
  connections: Connection[];
  archive: ChannelArchive;
};

export type ChannelQueryParams = QueryParams & ({ status: ChannelStatus[] } | { createdFrom: string; createdTo?: string });

export type ChannelCreateParams = ChannelRequiredProps & ChannelOptionalProps;

export type ChannelUpdateParams = Partial<ChannelRequiredProps> &
  Omit<ChannelOptionalProps, 'spotlight'> &
  Pick<ChannelProvidedProps, 'referenceCandidate'>;

export type Channel = ChannelRequiredProps & ChannelOptionalProps & ChannelProvidedProps;

export function isChannelUpdateParams(value: unknown): value is ChannelUpdateParams {
  const sProps: Prop<ChannelUpdateParams, string>[] = ['name', 'ctrlNo', 'model', 'code', 'content', 'remarks'];
  const bProps: Prop<ChannelUpdateParams, boolean>[] = ['referenceCandidate'];
  return isObject(value) && (sProps.some(prop => isString(value[prop])) || bProps.every(prop => isBoolean(value[prop])));
}

export function isChannel(value: unknown): value is Channel {
  const sProps: Prop<RequiredOnly<Channel>, string>[] = ['name', 'channelId', 'endpointUrl'];
  const nProps: Prop<RequiredOnly<Channel>, number>[] = ['statInterval', 'createdAt', 'updatedAt', 'version'];
  const bProps: Prop<RequiredOnly<Channel>, boolean>[] = ['transcribe'];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    nProps.every(prop => isNumber(value[prop])) &&
    bProps.every(prop => isBoolean(value[prop]))
  );
}

export class Channels extends ImmutableMap<string, Channel> {
  constructor(channels?: Channel[] | Iterable<Channel>) {
    super((channel: Channel) => channel.channelId, channels);
  }

  protected clone(channels?: Channel[] | Iterable<Channel>): this {
    return new Channels(channels) as this;
  }

  override set(channel: Channel): this {
    if (channel.status !== 'standby' && channel.status !== 'active') {
      return super.delete(channel.channelId);
    }
    return super.set(channel);
  }
}
