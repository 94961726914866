import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { AsyncSubject, Observable, combineLatest, partition } from 'rxjs';
import { delayWhen, filter, finalize, repeatWhen, takeUntil } from 'rxjs/operators';

import {
  AuthUsecase,
  DistinctSubject,
  Period,
  PeriodSubject,
  UserTimezone,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import { ArchiveMeasurementFile, ArchiveMeasurementFileQueryParams, ArchiveMeasurementFileUpdateParams } from '../models/archive.model';
import { ArchiveGateway } from '../usecases/archive.gateway';
import { MeasurementFileUsecase } from '../usecases/measurement-file.usecase';

@Injectable()
export class MeasurementFileInteractor extends MeasurementFileUsecase {
  get measurementFiles$(): Observable<ArchiveMeasurementFile[]> {
    return this._measurementFiles;
  }
  get period$(): Observable<Period> {
    return this._period;
  }

  private readonly _measurementFiles = new DistinctSubject<ArchiveMeasurementFile[]>([]);
  private readonly _period = new PeriodSubject({ from: -1, to: -1 });

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _archiveGateway: ArchiveGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._period.now();
    });
    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    combineLatest([this._authUsecase.payload$, this.period$])
      .pipe(
        filter(([user, { from, to }]) => user.organizationId !== undefined && from >= 0 && to >= 0),
        takeUntil(close$),
        finalize(() => this._measurementFiles.next([])),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => open$))),
      )
      .subscribe(([user, { from, to }]) => {
        const organizationId = user.organizationId ? user.organizationId : '';
        const queryParams: ArchiveMeasurementFileQueryParams = { createdFrom: from.toString(), createdTo: to.toString() };
        recursiveQuery(params => this._archiveGateway.listArchiveMeasurementFiles(organizationId, params), queryParams).subscribe(
          measurements => this._measurementFiles.next(measurements),
        );
      });
  }

  changeTimezone(timezone: UserTimezone): void {
    this._period.tz(timezone);
  }

  changePeriod(period: Period): void {
    this._period.next(period);
  }

  reload(): void {
    const now = dayjs().tz();
    if (this._period.includes(now.startOf('day').unix())) {
      this._period.next({ from: this._period.value.from, to: now.unix() });
    }
  }

  getArchiveMeasurement(channelId: string, measurementId: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._archiveGateway.getArchiveMeasurement(channelId, measurementId).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateArchiveMeasurementFile(channelId: string, measurementId: string, params: ArchiveMeasurementFileUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._archiveGateway.updateArchiveMeasurementFile(channelId, measurementId, params).subscribe({
      next: updatedArchiveMeasurementFile => this.replaceMeasurementFile(updatedArchiveMeasurementFile),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private replaceMeasurementFile(measurementFile: ArchiveMeasurementFile): void {
    const measurementFiles = this._measurementFiles.value;
    const index = measurementFiles.findIndex(({ measurementId }) => measurementId === measurementFile.measurementId);
    if (index < 0) {
      return;
    }
    measurementFiles[index] = measurementFile;
    this._measurementFiles.next([...measurementFiles]);
  }
}
