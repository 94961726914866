import { Observable } from 'rxjs';

import { CategoryType, Property } from '../models/property.model';
import {
  Reference,
  ReferenceCreateParams,
  ReferenceUpdateParams,
  ReferenceVideo,
  ReferenceVideoCreateParams,
  ReferenceVideoUpdateParams,
} from '../models/reference.model';

export abstract class ReferenceUsecase {
  abstract get references$(): Observable<(Reference & { videos$: Observable<ReferenceVideo[]> })[]>;
  abstract get properties$(): Observable<Record<CategoryType, Map<string, Property>>>;

  abstract createReference(params: ReferenceCreateParams): Observable<never>;
  abstract updateReference(referenceId: string, params: ReferenceUpdateParams): Observable<never>;
  abstract deleteReference(referenceId: string): Observable<never>;
  abstract createReferenceVideo(referenceId: string, video: Blob, params: ReferenceVideoCreateParams): Observable<never>;
  abstract getReferenceVideo(referenceId: string, videoId: string): Observable<string>;
  abstract updateReferenceVideo(referenceId: string, videoId: string, params: ReferenceVideoUpdateParams): Observable<never>;
  abstract deleteReferenceVideo(referenceId: string, videoId: string): Observable<never>;
}
