import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DistinctSubject, NeverError, WebSocketSyncData, WebSocketUsecase, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { Thinklet, ThinkletUpdateParams, Thinklets } from '../models/thinklet.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';
import { ThinkletUsecase } from '../usecases/thinklet.usecase';

@Injectable()
export class ThinkletInteractor extends ThinkletUsecase {
  get thinklets$(): Observable<Thinklets> {
    return this._thinklets;
  }

  private readonly _thinklets = new DistinctSubject<Thinklets>(new Thinklets());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _thinkletGateway: ThinkletGateway,
  ) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'thinklet'),
        map(({ data }) => data as WebSocketSyncData<Thinklet>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update': {
            const thinklet = data.payload;
            thinklet.commands$ = recursiveQuery(params => this._thinkletGateway.listThinkletCommands(thinklet.imei, params), {});
            this._thinklets.next(this._thinklets.value.set(thinklet));
            break;
          }
          case 'delete':
            this._thinklets.next(this._thinklets.value.delete(data.payload.imei));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  updateThinklets(params: ThinkletUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.updateThinklets(params).subscribe({
      next: ({ items }) => {
        const thinklets = items.reduce((acc, cur) => {
          cur.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(cur.imei, queryParams), {});
          return acc.set(cur);
        }, this._thinklets.value);
        this._thinklets.next(thinklets);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._thinkletGateway.listThinklets(params), {}).subscribe(thinklets => {
      thinklets.forEach(thinklet => {
        thinklet.commands$ = recursiveQuery(params => this._thinkletGateway.listThinkletCommands(thinklet.imei, params), {});
      });
      this._thinklets.next(new Thinklets(thinklets));
    });
  }

  private onSignOut(): void {
    this._thinklets.next(new Thinklets());
  }
}
