import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Thinklet, ThinkletCommand, ThinkletCommandQueryParams, ThinkletQueryParams, ThinkletUpdateParams } from '../models/thinklet.model';

export abstract class ThinkletGateway {
  abstract listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>>;
  abstract updateThinklets(params: ThinkletUpdateParams): Observable<ListResponse<Thinklet>>;
  abstract listThinkletCommands(imei: string, params: ThinkletCommandQueryParams): Observable<ListResponse<ThinkletCommand>>;
}
