import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Firmware, FirmwareCreateParams, FirmwareQueryParams, FirmwareType, FirmwareUpdateParams } from '../models/firmware.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { FirmwareGateway } from '../usecases/firmware.gateway';
import { FirmwareUsecase } from '../usecases/firmware.usecase';

@Injectable()
export class FirmwareInteractor extends FirmwareUsecase {
  get firmwares$(): Observable<Firmware[]> {
    return this._firmwares;
  }

  private readonly _firmwares = new DistinctSubject<Firmware[]>([]);

  constructor(private _authUsecase: AuthUsecase, private _firmwareGateway: FirmwareGateway) {
    super();
    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  createFirmware(type: FirmwareType, params: FirmwareCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._firmwareGateway.createFirmware(type, params).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateFirmware(type: FirmwareType, firmware: string, params: FirmwareUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._firmwareGateway.updateFirmware(type, firmware, params).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteFirmware(type: FirmwareType, firmware: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._firmwareGateway.deleteFirmware(type, firmware).subscribe({
      next: () => this.reload(),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reload(): void {
    this.onSignIn();
  }

  private onSignIn(): void {
    recursiveQuery<FirmwareQueryParams, Firmware>(params => this._firmwareGateway.listFirmwares('thinklet', params), {}).subscribe(
      firmwares => this._firmwares.next(firmwares),
    );
  }

  private onSignOut(): void {
    this._firmwares.next([]);
  }
}
