import { ImmutableMap, Prop, QueryParams, RequiredOnly, isObject, isString } from '@daikin-tic/dxone-com-lib';

export const ACCESS_POINT_AUTH_TYPES = [0, 1, 2, 3] as const;
export type AccessPointAuthType = (typeof ACCESS_POINT_AUTH_TYPES)[number];

export const ACCESS_POINT_PROTOCOLS = ['IP', 'IPV6', 'IPV4V6'] as const;
export type AccessPointProtocol = (typeof ACCESS_POINT_PROTOCOLS)[number];

export const ACCESS_POINT_BEARERS = [0, 14, 15, 11, 10, 9, 3, 2, 1, 13, 12, 8, 7, 6, 5, 4, 20] as const;
export type AccessPointBearer = (typeof ACCESS_POINT_BEARERS)[number];

export const ACCESS_POINT_MVNO_TYPES = ['', 'spn', 'imsi', 'gid'] as const;
export type AccessPointMvnoType = (typeof ACCESS_POINT_MVNO_TYPES)[number];

type AccessPointRequiredProps = {
  name: string;
  apn: string;
  mcc: string;
  mnc: string;
};

type AccessPointOptionalProps = {
  proxy?: string;
  port?: string;
  user?: string;
  password?: string;
  server?: string;
  mmsc?: string;
  mmsproxy?: string;
  mmsport?: string;
  authType?: AccessPointAuthType;
  type?: string;
  protocol?: AccessPointProtocol;
  roamingProtocol?: AccessPointProtocol;
  carrierEnabled?: string;
  bearer?: AccessPointBearer;
  bearerBitmask?: number;
  mvnoType?: AccessPointMvnoType;
  mvnoMatchDate?: string;
};

type AccessPointProvidedProps = {
  accessPointId: string;
  organizationId: string;
  updatedAt: number;
  version: number;
};

export type AccessPointQueryParams = QueryParams & { organizationId?: string };

export type AccessPointCreateParams = AccessPointRequiredProps & AccessPointOptionalProps & { organizationId?: string };

export type AccessPoint = AccessPointRequiredProps & AccessPointOptionalProps & AccessPointProvidedProps;

export function isAccessPointCreateParams(value: unknown): value is AccessPointCreateParams {
  const sProps: Prop<RequiredOnly<AccessPointCreateParams>, string>[] = ['name', 'apn', 'mcc', 'mnc'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}

export class AccessPoints extends ImmutableMap<string, AccessPoint> {
  constructor(accessPoints?: AccessPoint[] | Iterable<AccessPoint>) {
    super(accessPoint => accessPoint.accessPointId, accessPoints);
  }

  protected clone(accessPoints?: AccessPoint[] | Iterable<AccessPoint>): this {
    return new AccessPoints(accessPoints) as this;
  }
}
