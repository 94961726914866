import { Observable } from 'rxjs';

import { AuthSession, AuthUser } from '../models/auth.model';

export abstract class AuthGateway {
  abstract currentUser(bypassCache?: boolean): Observable<AuthUser>;
  abstract currentSession(): Observable<AuthSession>;
  abstract signIn(username: string, password: string): Observable<AuthUser>;
  abstract confirmSignIn(user: AuthUser, code: string): Observable<AuthUser>;
  abstract completeNewPassword(user: AuthUser, password: string): Observable<AuthUser>;
  abstract signOut(): Observable<unknown>;
  abstract forgotPassword(username: string): Observable<unknown>;
  abstract forgotPasswordSubmit(username: string, code: string, password: string): Observable<string>;
  abstract changePassword(user: AuthUser, oldPassword: string, newPassword: string): Observable<'SUCCESS'>;
  abstract updateUserAttribute(user: AuthUser, attr: string, value: string): Observable<never>;
  abstract verifyUserAttribute(user: AuthUser, attr: string): Observable<never>;
  abstract verifyUserAttributeSubmit(user: AuthUser, attr: string, code: string): Observable<never>;
  abstract deleteUserAttribute(user: AuthUser, attr: string): Observable<never>;
  abstract setPreferredMFA(user: AuthUser, mfaMethod: 'TOTP' | 'SMS' | 'NOMFA'): Observable<never>;
}
