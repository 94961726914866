import { Observable } from 'rxjs';

import { User } from './user.model';
import { ImmutableMap, Prop, QueryParams, RequiredOnly, isBoolean, isNumber, isObject, isString } from './utility.model';

type OrganizationRestriction = {
  dormant?: boolean;
  thinklet?: boolean;
  browser?: boolean;
};

type OrganizationRequiredProps = {
  name: string;
  firmware: string;
  software: string;
  transcribe: boolean;
  measurement: boolean;
};

type OrganizationOptionalProps = {
  remarks?: string;
};

type OrganizationProvidedProps = {
  organizationId: string;
  restriction?: OrganizationRestriction;
  requireMfa: boolean;
  updatedAt: number;
  version: number;
};

export type OrganizationQueryParams = QueryParams;

export type OrganizationCreateParams = OrganizationRequiredProps & OrganizationOptionalProps;

export type OrganizationUpdateParams = Partial<OrganizationRequiredProps> & OrganizationOptionalProps;

export type Organization = OrganizationRequiredProps &
  OrganizationOptionalProps &
  OrganizationProvidedProps & { users$?: Observable<User[]> };

export function isOrganizationCreateParams(value: unknown): value is OrganizationCreateParams {
  const sProps: Prop<RequiredOnly<OrganizationCreateParams>, string>[] = ['name', 'firmware', 'software'];
  const bProps: Prop<RequiredOnly<OrganizationCreateParams>, boolean>[] = ['transcribe', 'measurement'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && bProps.every(prop => isBoolean(value[prop]));
}

export function isOrganizationUpdateParams(value: unknown): value is OrganizationUpdateParams {
  const sProps: Prop<OrganizationUpdateParams, string>[] = ['name', 'firmware', 'software', 'remarks'];
  const bProps: Prop<OrganizationUpdateParams, boolean>[] = ['transcribe', 'measurement'];
  return isObject(value) && (sProps.some(prop => isString(value[prop])) || bProps.some(prop => isBoolean(value[prop])));
}

export function isOrganization(value: unknown): value is Organization {
  const sProps: Prop<RequiredOnly<Organization>, string>[] = ['organizationId', 'name', 'firmware', 'software'];
  const nProps: Prop<RequiredOnly<Organization>, number>[] = ['updatedAt', 'version'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && nProps.every(prop => isNumber(value[prop]));
}

export class Organizations extends ImmutableMap<string, Organization> {
  constructor(organizations?: Organization[] | Iterable<Organization>) {
    super((organization: Organization) => organization.organizationId, organizations);
  }

  protected clone(organizations?: Organization[] | Iterable<Organization>): this {
    return new Organizations(organizations) as this;
  }
}
