import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Offer, OfferCreateParams, OfferMultipleCreateParams, OfferQueryParams, OfferUpdateParams } from '../models/offer.model';

export abstract class OfferGateway {
  abstract listOffers(params: OfferQueryParams): Observable<ListResponse<Offer>>;
  abstract createOffer(params: OfferCreateParams): Observable<Offer>;
  abstract createOfferMultiple(params: OfferMultipleCreateParams): Observable<ListResponse<Offer>>;
  abstract updateOffer(offerId: string, params: OfferUpdateParams): Observable<Offer>;
}
