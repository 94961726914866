import { Observable } from 'rxjs';

import { Period, UserTimezone } from '@daikin-tic/dxone-com-lib';

import { Noise, NoiseTargets, NoiseUpdateParams } from '../models/noise.model';

export abstract class NoiseArchiveUsecase {
  abstract get noises$(): Observable<Noise[]>;
  abstract get period$(): Observable<Period>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;
  abstract reload(): void;

  abstract getNoise(noiseId: string, target: NoiseTargets): Observable<string>;
  abstract updateNoise(noiseId: string, params: NoiseUpdateParams): Observable<Noise>;
}
