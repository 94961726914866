import { Observable } from 'rxjs';

import { AccessPointCreateParams, AccessPoints } from '../models/access-point.model';

export abstract class AccessPointUsecase {
  abstract get accessPoints$(): Observable<AccessPoints>;

  abstract createAccessPoint(params: AccessPointCreateParams): Observable<never>;
  abstract deleteAccessPoint(accessPointId: string): Observable<never>;
}
