import { Observable } from 'rxjs';

import { Period, UserTimezone } from '@daikin-tic/dxone-com-lib';

import { ArchiveChannel, ArchiveCombineVideoCreateParams } from '../models/archive.model';
import { ChannelUpdateParams } from '../models/channel.model';

export abstract class RemoteArchiveUsecase {
  abstract get channels$(): Observable<ArchiveChannel[]>;
  abstract get period$(): Observable<Period>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;
  abstract reload(): void;

  abstract updateChannel(channelId: string, params: ChannelUpdateParams): Observable<never>;
  abstract deleteChannel(channelId: string): Observable<never>;

  abstract getArchiveVideo(channelId: string, connectionId: string): Observable<string>;
  abstract deleteArchiveVideo(channelId: string, connectionId: string): Observable<never>;

  abstract createArchiveCombineVideo(channelId: string, params: ArchiveCombineVideoCreateParams): Observable<never>;
  abstract getArchiveCombineVideo(channelId: string, videoId: string): Observable<string>;
  abstract deleteArchiveCombineVideo(channelId: string, videoId: string): Observable<never>;

  abstract deleteArchiveMessages(channelId: string): Observable<never>;

  abstract getSnapshot(channelId: string, snapshotId: string, filename?: string): Observable<string>;
  abstract deleteSnapshot(channelId: string, snapshotId: string): Observable<never>;

  abstract getArchiveMeasurement(channelId: string, measurementId: string): Observable<string>;
  abstract deleteArchiveMeasurement(channelId: string, measurementId: string): Observable<never>;
}
