import { Observable } from 'rxjs';

import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

export type ThinkletImportErrorReason = 'imei' | 'organizationId' | 'locationId' | 'commands';

export type ThinkletImportError = {
  imei: string;
  reason: ThinkletImportErrorReason;
};

export type ThinkletCommandStatus = 'sending' | 'waiting' | 'success' | 'failure';

export type ThinkletCommandTrouble =
  | 'none'
  | 'excess'
  | 'incorrect-imei'
  | 'timeout'
  | 'transaction-failure'
  | 'downgrade'
  | 'cancel'
  | 'internal-error';

export const THINKLET_LANGS = ['ja', 'en'] as const;
export type ThinkletLang = (typeof THINKLET_LANGS)[number];

export const THINKLET_COMMAND_TYPES = ['apn', 'wifi', 'lang', 'firmware', 'software'] as const;
export type ThinkletCommandType = (typeof THINKLET_COMMAND_TYPES)[number];

export type ThinkletCommand = {
  commandId: string;
  status: ThinkletCommandStatus;
  trouble: ThinkletCommandTrouble;
  type: ThinkletCommandType;
  contents: string;
  metadata: string;
  imei: string;
  registeredAt: number;
  updatedAt: number;
  progress?: number;
};

export const THINKLET_STATUSES = [
  'sending',
  'activating',
  'updating',
  'registered',
  'connecting',
  'available',
  'deactivating',
  'unavailable',
  'deleting',
  'failure',
  'missed',
  'unusable',
  'remained',
] as const;
export type ThinkletStatus = (typeof THINKLET_STATUSES)[number];

export type ThinkletTrialStatus = 'disabled' | 'deactivate' | 'enabled' | 'activate';

type ThinkletRequiredProps = {
  name: string;
  imei: string;
  organizationId: string;
  locationId: string;
  commands: Pick<ThinkletCommand, 'type' | 'contents'>[];
};

type ThinkletProvidedProps = {
  deviceId: string;
  apn: string;
  wifi: string;
  lang: ThinkletLang;
  firmware: string;
  software: string;
  status: ThinkletStatus;
  trial: ThinkletTrialStatus;
  trouble: ThinkletCommandTrouble;
  registerId: string;
  registeredAt: number;
  updatedAt: number;
  version: number;
  commands: Pick<ThinkletCommand, 'type' | 'contents' | 'metadata' | 'commandId' | 'status' | 'trouble' | 'progress'>[];
};

export type ThinkletCommandQueryParams = QueryParams;

export type ThinkletQueryParams = QueryParams;

export type ThinkletCreateParams = {
  items: ThinkletRequiredProps[];
};

export type ThinkletUpdateParams = {
  items: Pick<ThinkletRequiredProps, 'imei' | 'commands'>[];
};

export type ThinkletRetryParams = {
  items: Pick<ThinkletRequiredProps, 'imei'>[];
};

export type ThinkletReactivateParams = Omit<ThinkletRequiredProps, 'imei'>;

export type Thinklet = Omit<ThinkletRequiredProps, 'name' | 'commands'> &
  ThinkletProvidedProps & { commands$?: Observable<ThinkletCommand[]> };

export class Thinklets extends ImmutableMap<string, Thinklet> {
  constructor(thinklets?: Thinklet[] | Iterable<Thinklet>) {
    super(thinklet => thinklet.imei, thinklets);
  }

  protected clone(thinklets?: Thinklet[] | Iterable<Thinklet>): this {
    return new Thinklets(thinklets) as this;
  }
}
