import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Software, SoftwareCreateParams, SoftwareQueryParams, SoftwareType, SoftwareUpdateParams } from '../models/software.model';
import { ListResponse } from '../models/utility.model';
import { SoftwareGateway } from '../usecases/software.gateway';

@Injectable()
export class SoftwareService extends SoftwareGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listSoftware(type: SoftwareType, params: SoftwareQueryParams): Observable<ListResponse<Software>> {
    return this._http.get<ListResponse<Software>>(`api/com/software-registers/${type}`, { params });
  }

  createSoftware(type: SoftwareType, params: SoftwareCreateParams): Observable<Software> {
    return this._http.post<Software>(`api/com/software-registers/${type}`, params);
  }

  updateSoftware(type: SoftwareType, software: string, params: SoftwareUpdateParams): Observable<Software> {
    return this._http.put<Software>(`api/com/software-registers/${type}/software/${software}`, params);
  }

  deleteSoftware(type: SoftwareType, software: string): Observable<void> {
    return this._http.delete<void>(`api/com/software-registers/${type}/software/${software}`);
  }
}
