import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import {
  ArchiveCombineVideo,
  ArchiveCombineVideoCreateParams,
  ArchiveCombineVideoQueryParams,
  ArchiveMeasurement,
  ArchiveMeasurementFile,
  ArchiveMeasurementFileQueryParams,
  ArchiveMeasurementFileUpdateParams,
  ArchiveMeasurementQueryParams,
  ArchiveVideo,
  ArchiveVideoQueryParams,
} from '../models/archive.model';
import { Snapshot, SnapshotQueryParams } from '../models/snapshot.model';
import { ArchiveGateway } from '../usecases/archive.gateway';

@Injectable()
export class ArchiveService extends ArchiveGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listArchiveVideos(channelId: string, params: ArchiveVideoQueryParams): Observable<ListResponse<ArchiveVideo>> {
    return this._http.get<ListResponse<ArchiveVideo>>(`api/rss/archives/${channelId}/videos`, { params });
  }

  getArchiveVideo(channelId: string, connectionId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/archives/${channelId}/videos/${connectionId}`);
  }

  deleteArchiveVideo(channelId: string, connectionId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/archives/${channelId}/videos/${connectionId}`);
  }

  listArchiveCombineVideos(channelId: string, params: ArchiveCombineVideoQueryParams): Observable<ListResponse<ArchiveCombineVideo>> {
    return this._http.get<ListResponse<ArchiveCombineVideo>>(`api/rss/archives/${channelId}/combine-videos`, { params });
  }

  createArchiveCombineVideo(channelId: string, params: ArchiveCombineVideoCreateParams): Observable<ArchiveCombineVideo> {
    return this._http.post<ArchiveCombineVideo>(`api/rss/archives/${channelId}/combine-videos`, params);
  }

  getArchiveCombineVideo(channelId: string, videoId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/archives/${channelId}/combine-videos/${videoId}`);
  }

  deleteArchiveCombineVideo(channelId: string, videoId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/archives/${channelId}/combine-videos/${videoId}`);
  }

  deleteArchiveMessages(channelId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/archives/${channelId}/messages`);
  }

  listSnapshots(channelId: string, params: SnapshotQueryParams): Observable<ListResponse<Snapshot>> {
    return this._http.get<ListResponse<Snapshot>>(`api/rss/archives/${channelId}/snapshots`, { params });
  }

  getSnapshot(channelId: string, snapshotId: string, filename?: string): Observable<{ url: string }> {
    const query = filename ? `?fileName=${filename}` : '';
    return this._http.get<{ url: string }>(`api/rss/archives/${channelId}/snapshots/${snapshotId}${query}`);
  }

  deleteSnapshot(channelId: string, snapshotId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/archives/${channelId}/snapshots/${snapshotId}`);
  }

  listArchiveMeasurements(channelId: string, params: ArchiveMeasurementQueryParams): Observable<ListResponse<ArchiveMeasurement>> {
    return this._http.get<ListResponse<ArchiveMeasurement>>(`api/rss/archives/${channelId}/measurements`, { params });
  }

  getArchiveMeasurement(channelId: string, measurementId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`api/rss/archives/${channelId}/measurements/${measurementId}`);
  }

  deleteArchiveMeasurement(channelId: string, measurementId: string): Observable<void> {
    return this._http.delete<void>(`api/rss/archives/${channelId}/measurements/${measurementId}`);
  }

  listArchiveMeasurementFiles(
    channelId: string,
    params: ArchiveMeasurementFileQueryParams,
  ): Observable<ListResponse<ArchiveMeasurementFile>> {
    return this._http.get<ListResponse<ArchiveMeasurementFile>>(`api/rss/archives/${channelId}/measurements`, { params });
  }

  updateArchiveMeasurementFile(
    channelId: string,
    measurementId: string,
    params: ArchiveMeasurementFileUpdateParams,
  ): Observable<ArchiveMeasurementFile> {
    return this._http.put<ArchiveMeasurementFile>(`api/rss/archives/${channelId}/measurements/${measurementId}`, params);
  }
}
