import { Observable } from 'rxjs';

import { Software, SoftwareCreateParams, SoftwareQueryParams, SoftwareType, SoftwareUpdateParams } from '../models/software.model';
import { ListResponse } from '../models/utility.model';

export abstract class SoftwareGateway {
  abstract listSoftware(type: SoftwareType, params: SoftwareQueryParams): Observable<ListResponse<Software>>;
  abstract createSoftware(type: SoftwareType, params: SoftwareCreateParams): Observable<Software>;
  abstract updateSoftware(type: SoftwareType, software: string, params: SoftwareUpdateParams): Observable<Software>;
  abstract deleteSoftware(type: SoftwareType, software: string): Observable<void>;
}
