import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Device, DeviceUpdateParams, Devices } from '../models/device.model';
import { NeverError } from '../models/error.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { DeviceGateway } from '../usecases/device.gateway';
import { DeviceUsecase } from '../usecases/device.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class DeviceInteractor extends DeviceUsecase {
  get devices$(): Observable<Devices> {
    return this._devices;
  }

  private readonly _devices = new DistinctSubject<Devices>(new Devices());

  constructor(private _authUsecase: AuthUsecase, private _webSocketUsecase: WebSocketUsecase, private _deviceGateway: DeviceGateway) {
    super();

    if (!this._webSocketUsecase.enabled) {
      this._authUsecase.authState$
        .pipe(
          map(({ status }) => status === 'signedIn'),
          distinctUntilChanged(),
        )
        .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
      return;
    }

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'device'),
        map(({ data }) => data as WebSocketSyncData<Device>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._devices.next(this._devices.value.set(data.payload));
            break;
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  updateDevice(deviceId: string, params: DeviceUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._deviceGateway.updateDevice(deviceId, params).subscribe({
      next: updatedDevice => this._devices.next(this._devices.value.set(updatedDevice)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reload(): void {
    this.onSignIn();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._deviceGateway.listDevices(params), {}).subscribe(devices => {
      this._devices.next(new Devices(devices));
    });
  }

  private onSignOut(): void {
    this._devices.next(new Devices());
  }
}
