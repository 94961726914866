import { Observable } from 'rxjs';

import { Period, UserTimezone } from '@daikin-tic/dxone-com-lib';

import { Record, RecordUpdateParams } from '../models/record.model';

export abstract class LocalArchiveUsecase {
  abstract get records$(): Observable<Record[]>;
  abstract get period$(): Observable<Period>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;
  abstract reload(): void;

  abstract updateRecord(recordId: string, params: RecordUpdateParams): Observable<never>;

  abstract getRecordVideo(recordId: string, videoId: string): Observable<string>;
  abstract deleteRecordVideo(recordId: string, videoId: string): Observable<never>;
}
