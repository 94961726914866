import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { Report, ReportQueryParams } from '../models/report.model';
import { ReportGateway } from '../usecases/report.gateway';

@Injectable()
export class ReportService extends ReportGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listReports(params: ReportQueryParams): Observable<ListResponse<Report>> {
    return this._http.get<ListResponse<Report>>('api/vgs/reports', { params });
  }
}
