import { Observable } from 'rxjs';

import { Software, SoftwareCreateParams, SoftwareType, SoftwareUpdateParams } from '../models/software.model';

export abstract class SoftwareUsecase {
  abstract get softwares$(): Observable<Software[]>;

  abstract createSoftware(type: SoftwareType, params: SoftwareCreateParams): Observable<never>;
  abstract updateSoftware(type: SoftwareType, software: string, params: SoftwareUpdateParams): Observable<never>;
  abstract deleteSoftware(type: SoftwareType, software: string): Observable<never>;

  abstract reload(): void;
}
