import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Observable, defer, merge, timer } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';

import { AuthPayload, AuthSession, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  currentUser(bypassCache = false): Observable<AuthUser> {
    const currentUser$ = defer(() => Auth.currentUserPoolUser({ bypassCache }));
    const bypassCache$ = new Observable<never>(observer => {
      const cancel = Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'tokenRefresh':
            cancel();
            observer.complete();
            break;
          case 'tokenRefresh_failure':
            cancel();
            observer.error(data);
            break;
          default:
            // noop.
            break;
        }
      });
      timer(4e4).subscribe(() => {
        cancel();
        observer.error(new Error('Token refresh timeout.'));
      });
    });
    return bypassCache ? merge(bypassCache$, currentUser$) : currentUser$;
  }

  currentSession(): Observable<AuthSession> {
    return defer(() => Auth.currentSession()).pipe(
      map(session => ({ token: session.getIdToken().getJwtToken(), payload: session.getIdToken().payload as AuthPayload })),
    );
  }

  signIn(username: string, password: string): Observable<AuthUser> {
    return defer(() => Auth.signIn({ username, password }));
  }

  confirmSignIn(user: AuthUser, code: string): Observable<AuthUser> {
    return defer(() => Auth.confirmSignIn(user, code).then(() => Auth.currentUserPoolUser()));
  }

  completeNewPassword(user: AuthUser, password: string): Observable<AuthUser> {
    return defer(() => Auth.completeNewPassword(user, password, false));
  }

  signOut(): Observable<unknown> {
    return defer(() => Auth.signOut());
  }

  forgotPassword(username: string): Observable<unknown> {
    return defer(() => Auth.forgotPassword(username));
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<string> {
    return defer(() => Auth.forgotPasswordSubmit(username, code, password));
  }

  changePassword(user: AuthUser, oldPassword: string, newPassword: string): Observable<'SUCCESS'> {
    return defer(() => Auth.changePassword(user, oldPassword, newPassword));
  }

  updateUserAttribute(user: AuthUser, attr: string, value: string): Observable<never> {
    return defer(() => Auth.updateUserAttributes(user, { [attr]: value })).pipe(ignoreElements());
  }

  verifyUserAttribute(user: AuthUser, attr: string): Observable<never> {
    return defer(() => Auth.verifyUserAttribute(user, attr)).pipe(ignoreElements());
  }

  verifyUserAttributeSubmit(user: AuthUser, attr: string, code: string): Observable<never> {
    return defer(() => Auth.verifyUserAttributeSubmit(user, attr, code)).pipe(ignoreElements());
  }

  deleteUserAttribute(user: AuthUser, attr: string): Observable<never> {
    return defer(() => Auth.deleteUserAttributes(user, [attr])).pipe(ignoreElements());
  }

  setPreferredMFA(user: AuthUser, mfaMethod: 'TOTP' | 'SMS' | 'NOMFA'): Observable<never> {
    return defer(() => Auth.setPreferredMFA(user, mfaMethod)).pipe(ignoreElements());
  }
}
