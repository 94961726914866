import { ImmutableMap, QueryParams } from '@daikin-tic/dxone-com-lib';

export type MessageStatus = 'in-progress' | 'finished' | 'failed';

type MessageRequiredProps = {
  connectionId: string;
  status: MessageStatus;
  message: string;
  lang: string;
  timestamp: number;
};

type MessageOptionalProps = object;

type MessageProvidedProps = {
  channelId: string;
  messageId: string;
  updatedAt: number;
  version: number;
};

export type MessageQueryParams = QueryParams;

export type MessageCreateParams = MessageRequiredProps & MessageOptionalProps;

export type MessageUpdateParams = Partial<Pick<MessageRequiredProps, 'status' | 'message'>> & MessageOptionalProps;

export type Message = MessageRequiredProps & MessageOptionalProps & MessageProvidedProps;

export class Messages extends ImmutableMap<string, Message> {
  constructor(messages?: Message[] | Iterable<Message>) {
    super((message: Message) => message.messageId, messages);
  }

  protected clone(messages?: Message[] | Iterable<Message>): this {
    return new Messages(messages) as this;
  }

  override set(message: Message): this {
    if (message.status === 'failed') {
      return super.delete(message.messageId);
    }
    return super.set(message);
  }
}
