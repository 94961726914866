import { Prop, RequiredOnly, isObject, isString } from './utility.model';

export type MgmtDeptRestrictionSetting = {
  manual: boolean;
  auto: boolean;
  time: number;
};

export type MgmtDeptRestriction = {
  thinklet?: MgmtDeptRestrictionSetting;
  browser?: MgmtDeptRestrictionSetting;
};

export type MgmtDept = {
  mgmtDeptId: string;
  name: string;
  restriction?: MgmtDeptRestriction;
};

export function isMgmtDept(value: unknown): value is MgmtDept {
  const sProps: Prop<RequiredOnly<MgmtDept>, string>[] = ['mgmtDeptId', 'name'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}
