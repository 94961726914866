import { Observable } from 'rxjs';

import { DeviceUpdateParams, Devices } from '../models/device.model';

export abstract class DeviceUsecase {
  abstract get devices$(): Observable<Devices>;

  abstract updateDevice(deviceId: string, params: DeviceUpdateParams): Observable<never>;

  abstract reload(): void;
}
