import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, of } from 'rxjs';
import { concatMap, delay, filter, finalize, map, mergeMap, toArray } from 'rxjs/operators';

import {
  DistinctSubject,
  NeverError,
  ProgressUsecase,
  WebSocketSyncData,
  WebSocketUsecase,
  recursiveQuery,
} from '@daikin-tic/dxone-com-lib';

import { CATEGORY_TYPES, CategoryType, Property, PropertyQueryParams } from '../models/property.model';
import {
  Reference,
  ReferenceCreateParams,
  ReferenceUpdateParams,
  ReferenceVideo,
  ReferenceVideoCreateParams,
  ReferenceVideoUpdateParams,
  References,
} from '../models/reference.model';
import { CloudStorageGateway } from '../usecases/cloud-storage.gateway';
import { PropertyGateway } from '../usecases/property.gateway';
import { ReferenceGateway } from '../usecases/reference.gateway';
import { ReferenceUsecase } from '../usecases/reference.usecase';

const UPLOAD_DELAY = 10 * 1000; // 10sec
const createEmptyProps = () => ({
  type: new Map(),
  model: new Map(),
  code: new Map(),
  content: new Map(),
  part: new Map(),
  condition: new Map(),
  work: new Map(),
});

@Injectable()
export class ReferenceInteractor extends ReferenceUsecase {
  get references$(): Observable<(Reference & { videos$: Observable<ReferenceVideo[]> })[]> {
    return this._references.pipe(
      map(references => references.values().map(reference => ({ ...reference, videos$: this.listReferenceVideos(reference.referenceId) }))),
    );
  }
  get properties$(): Observable<Record<CategoryType, Map<string, Property>>> {
    return this._properties;
  }

  private readonly _references = new DistinctSubject<References>(new References());
  private readonly _properties = new DistinctSubject<Record<CategoryType, Map<string, Property>>>(createEmptyProps());

  constructor(
    private _progressUsecase: ProgressUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _cloudStorageGateway: CloudStorageGateway,
    private _referenceGateway: ReferenceGateway,
    private _propertyGateway: PropertyGateway,
  ) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'reference'),
        map(({ data }) => data as WebSocketSyncData<Reference>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._references.next(this._references.value.set(data.payload));
            break;
          case 'delete':
            this._references.next(this._references.value.delete(data.payload.referenceId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createReference(params: ReferenceCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._referenceGateway.createReference(params).subscribe({
      next: createdReference => this._references.next(this._references.value.set(createdReference)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateReference(referenceId: string, params: ReferenceUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._referenceGateway.updateReference(referenceId, params).subscribe({
      next: updatedReference => this._references.next(this._references.value.set(updatedReference)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteReference(referenceId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._referenceGateway.deleteReference(referenceId).subscribe({
      next: () => this._references.next(this._references.value.delete(referenceId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createReferenceVideo(referenceId: string, video: Blob, params: ReferenceVideoCreateParams): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._referenceGateway
      .createReferenceVideo(referenceId, params)
      .pipe(
        mergeMap(storage => this._cloudStorageGateway.upload(storage, video)),
        delay(UPLOAD_DELAY),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        next: progress => this._progressUsecase.update(progressId, progress),
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  getReferenceVideo(referenceId: string, videoId: string): Observable<string> {
    const result = new AsyncSubject<string>();
    this._referenceGateway.getReferenceVideo(referenceId, videoId).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateReferenceVideo(referenceId: string, videoId: string, params: ReferenceVideoUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._referenceGateway.updateReferenceVideo(referenceId, videoId, params).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteReferenceVideo(referenceId: string, videoId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._referenceGateway.deleteReferenceVideo(referenceId, videoId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._referenceGateway.listReferences(params), {}).subscribe(references =>
      this._references.next(new References(references)),
    );

    of(...CATEGORY_TYPES)
      .pipe(
        concatMap(category =>
          recursiveQuery<PropertyQueryParams, Property>(params => this._propertyGateway.listProperties(params), {
            category,
          }),
        ),
        map(props => new Map(props.map(prop => [prop.id, prop]))),
        toArray(),
        map(props => props.reduce((acc, cur, i) => ({ ...acc, [CATEGORY_TYPES[i]]: cur }), createEmptyProps())),
      )
      .subscribe(properties => this._properties.next(properties));
  }

  private onSignOut(): void {
    this._references.next(new References());
    this._properties.next(createEmptyProps());
  }

  private listReferenceVideos(referenceId: string): Observable<ReferenceVideo[]> {
    return recursiveQuery(params => this._referenceGateway.listReferenceVideos(referenceId, params), {});
  }
}
