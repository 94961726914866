import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Snapshot, SnapshotCreateParams } from '../models/snapshot.model';
import { SnapshotGateway } from '../usecases/snapshot.gateway';

@Injectable()
export class SnapshotService extends SnapshotGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  createSnapshot(channelId: string, params: SnapshotCreateParams): Observable<Snapshot> {
    return this._http.post<Snapshot>(`api/rss/channels/${channelId}/snapshots`, params);
  }
}
